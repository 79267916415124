import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, Avatar, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination } from '@mui/material';
import { styled } from '@mui/material/styles';
import axios from 'axios';

const OverviewContainer = styled(Box)({
    padding: '2rem',
    backgroundColor: '#1c1c1c',
    color: '#fff',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
    paddingBottom: '6rem',
});

const SectionTitle = styled(Typography)({
    color: '#12c099',
    fontWeight: 'bold',
    marginBottom: '1rem',
});

const Footer = styled(Box)({
    padding: '1rem',
    textAlign: 'center',
    color: '#fff',
    backgroundColor: 'transparent',
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
});

const SiteManager = () => {
    const { firebaseUid } = useParams();
    const [siteManager, setSiteManager] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Fetch site manager data by firebaseUid
        const fetchSiteManager = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/site-manager/${firebaseUid}`);
                setSiteManager(response.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching site manager data:', error);
                setLoading(false);
            }
        };
        fetchSiteManager();
    }, [firebaseUid]);

    if (loading) {
        return <Typography variant="h6" color="inherit">Loading...</Typography>;
    }

    if (!siteManager) {
        return <Typography variant="h6" color="inherit">Site Manager not found.</Typography>;
    }

    return (
        <OverviewContainer>
            <Typography variant="h4" gutterBottom>Site Manager Overview</Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={4} md={3}>
                    <Avatar src={siteManager.avatarImageUrl || ''} sx={{ width: 120, height: 120 }} />
                </Grid>
                <Grid item xs={12} sm={8} md={9}>
                    <Typography variant="h5">{siteManager.name}</Typography>
                    <Typography variant="body1">Email: {siteManager.email}</Typography>
                    <Typography variant="body1">Phone: {siteManager.phone}</Typography>
                    <Typography variant="body1">Location: {`${siteManager.city}, ${siteManager.state}`}</Typography>
                    <Typography variant="body1">Rating: {siteManager.rating}</Typography>
                    <Typography variant="body1">Attendance Rate: {siteManager.attendanceRate}%</Typography>
                </Grid>
            </Grid>
            <Paper sx={{ padding: '1.5rem', backgroundColor: '#202020', color: '#fff', borderRadius: '8px' }}>
                <SectionTitle variant="h6">Transaction History</SectionTitle>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ color: '#fff' }}>Date</TableCell>
                                <TableCell sx={{ color: '#fff' }}>Amount</TableCell>
                                <TableCell sx={{ color: '#fff' }}>Status</TableCell>
                                <TableCell sx={{ color: '#fff' }}>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {siteManager.transactions.map((transaction) => (
                                <TableRow key={transaction._id}>
                                    <TableCell sx={{ color: '#fff' }}>{new Date(transaction.date).toLocaleDateString()}</TableCell>
                                    <TableCell sx={{ color: '#fff' }}>${transaction.amount}</TableCell>
                                    <TableCell sx={{ color: '#fff' }}>{transaction.status}</TableCell>
                                    <TableCell>
                                        {/* Actions can be added here if needed */}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    component="div"
                    count={siteManager.transactions.length}
                    rowsPerPage={5}
                    page={0}
                    onPageChange={() => {}}
                    onRowsPerPageChange={() => {}}
                    sx={{ backgroundColor: '#202020', color: '#fff' }}
                />
            </Paper>
            <Footer>
                <Typography variant="body2">
                    &copy; {new Date().getFullYear()} Mars Reel Media Corp. All rights reserved.
                </Typography>
            </Footer>
        </OverviewContainer>
    );
};

export default SiteManager;
