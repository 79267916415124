import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, Avatar, Grid, Paper, List, ListItem, ListItemText } from '@mui/material';
import { styled } from '@mui/material/styles';
import axios from 'axios';

const OverviewContainer = styled(Box)({
    padding: '2rem',
    backgroundColor: '#1c1c1c',
    color: '#fff',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
});

const ContentContainer = styled(Box)({
    flexGrow: 1,
    paddingBottom: '3rem',
});

const SectionTitle = styled(Typography)({
    color: '#12c099',
    fontWeight: 'bold',
    marginBottom: '1rem',
});

const Footer = styled(Box)({
    padding: '1rem',
    textAlign: 'center',
    color: '#fff',
    backgroundColor: 'transparent',
});

const Employee = () => {
    const { firebaseUid } = useParams(); // Use firebaseUid as the URL parameter
    const [employee, setEmployee] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchEmployee = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/employee/${firebaseUid}`);
                setEmployee(response.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching employee data:', error);
                setLoading(false);
            }
        };
        fetchEmployee();
    }, [firebaseUid]);

    if (loading) {
        return <Typography variant="h6" color="inherit">Loading...</Typography>;
    }

    if (!employee) {
        return <Typography variant="h6" color="inherit">Employee not found.</Typography>;
    }

    return (
        <OverviewContainer>
            <ContentContainer>
                <Typography variant="h4" gutterBottom>Employee Overview</Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={4} md={3}>
                        <Avatar src={employee.avatarImageUrl || ''} sx={{ width: 120, height: 120 }} />
                    </Grid>
                    <Grid item xs={12} sm={8} md={9}>
                        <Typography variant="h5">{employee.name}</Typography>
                        <Typography variant="body1">Title: {employee.title}</Typography>
                        <Typography variant="body1">
                            Location: {employee.location.city.join(', ')}, {employee.location.state.join(', ')}
                        </Typography>
                        <Typography variant="body1">Email: {employee.email}</Typography>
                        <Typography variant="body1">Phone: {employee.phoneNumber}</Typography>
                        <Typography variant="body1">Sport: {employee.sport}</Typography>
                    </Grid>
                </Grid>
                <Paper sx={{ padding: '1.5rem', backgroundColor: '#202020', color: '#fff', borderRadius: '8px' }}>
                    <SectionTitle variant="h6">Additional Information</SectionTitle>
                    <List>
                        <ListItem>
                            <ListItemText
                                primary="Position"
                                secondary={employee.position}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary="Quota"
                                secondary={`Daily: ${employee.quota.daily}, Weekly: ${employee.quota.weekly}, Monthly: ${employee.quota.monthly}`}
                            />
                        </ListItem>
                    </List>
                </Paper>
            </ContentContainer>
            <Footer>
                <Typography variant="body2">
                    &copy; {new Date().getFullYear()} Mars Reel Media Corp. All rights reserved.
                </Typography>
            </Footer>
        </OverviewContainer>
    );
};

export default Employee;
