import React, { useState } from 'react';
import { Box, Typography, Button, Grid, Chip } from '@mui/material';
import PaymentModal from './PaymentModal';

const PaymentManagement = ({ coaches, siteManagers }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedPerson, setSelectedPerson] = useState(null);

    const handlePayClick = (person) => {
        setSelectedPerson(person);
        setModalOpen(true);
    };

    const handleClose = () => {
        setModalOpen(false);
        setSelectedPerson(null);
    };

    return (
        <Box sx={{ mt: 4 }}>
            <Typography variant="h5" gutterBottom>Payment Management</Typography>
            <Grid container spacing={2}>
                {[...coaches, ...siteManagers].map((person, index) => (
                    <Grid item xs={12} sm={6} key={index}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2, bgcolor: '#282828', borderRadius: 2 }}>
                            <Box>
                                <Typography>{person.name}</Typography>
                                <Typography variant="body2">Rate: ${person.rate}/hour</Typography>
                                <Typography variant="body2">Hours: {person.owed / person.rate}</Typography>
                            </Box>
                            <Chip label={`Owed: $${person.owed}`} color="primary" />
                            <Button variant="contained" color="primary" onClick={() => handlePayClick(person)}>Pay</Button>
                        </Box>
                    </Grid>
                ))}
            </Grid>

            {selectedPerson && (
                <PaymentModal
                    open={modalOpen}
                    handleClose={handleClose}
                    details={`Paying ${selectedPerson.name} a total of $${selectedPerson.owed}`}
                />
            )}
        </Box>
    );
};

export default PaymentManagement;
