import React, { useEffect, useState } from 'react';
import {
    Box,
    Typography,
    Grid,
    Card,
    CardContent,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button
} from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import { useInView } from 'react-intersection-observer';

const ContentFeed = () => {
    const [videoList, setVideoList] = useState([]);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [videoToDelete, setVideoToDelete] = useState(null);

    useEffect(() => {
        const fetchVideos = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/videos`);
                if (Array.isArray(response.data)) {
                    const videosWithUploader = await Promise.all(response.data.map(async (video) => {
                        try {
                            const uploaderId = video.uploadedBy?._id || video.uploadedBy;
                            const uploaderResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/site-managers/${uploaderId}`);

                            if (uploaderResponse.data && uploaderResponse.data.name) {
                                return { ...video, uploaderName: uploaderResponse.data.name };
                            } else {
                                console.error(`Uploader data invalid for video ${video._id}:`, uploaderResponse.data);
                                return { ...video, uploaderName: 'Unknown' };
                            }
                        } catch (error) {
                            console.error(`Error fetching uploader name for video ${video._id}:`, error);
                            return { ...video, uploaderName: 'Unknown' };
                        }
                    }));
                    setVideoList(videosWithUploader);
                } else {
                    console.error('Expected an array but got:', response.data);
                    setVideoList([]);
                }
            } catch (error) {
                console.error('Error fetching videos:', error);
                setVideoList([]);
            }
        };
        fetchVideos();
    }, []);

    const handleDelete = async (id) => {
        console.log(`Attempting to delete video with ID: ${id}`); // Add this line for logging
        try {
            await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/videos/${id}`);
            setVideoList(videoList.filter((video) => video._id !== id));
        } catch (error) {
            console.error('Error deleting video:', error);
        }
    };


    const openDeleteDialog = (videoId) => {
        setVideoToDelete(videoId);
        setDeleteDialogOpen(true);
    };

    const closeDeleteDialog = () => {
        setDeleteDialogOpen(false);
        setVideoToDelete(null);
    };

    const confirmDelete = () => {
        if (videoToDelete) {
            handleDelete(videoToDelete);
        }
        closeDeleteDialog();
    };

    return (
        <ContentFeedContainer>
            <Typography variant="h4" gutterBottom>Content Feed</Typography>
            <Grid container spacing={2}> {/* Main grid container */}
                {videoList.map((video) => (
                    <Grid item xs={12} sm={6} md={4} key={video._id}> {/* Each video grid item */}
                        <Card sx={{ position: 'relative', backgroundColor: '#282828', color: '#fff', borderRadius: '10px', overflow: 'hidden' }}>
                            <video
                                src={video.videoUrl}
                                controls
                                onError={() => console.error(`Failed to load video from URL: ${video.videoUrl}`)}
                                style={{ height: 'auto', width: '100%', objectFit: 'cover' }}
                                alt={`Video uploaded by ${video.uploaderName || 'Unknown'}`}
                                loading="lazy"
                            />
                            <CardContent sx={{ padding: '0.5rem', backgroundColor: '#333' }}>
                                <Typography variant="body2" sx={{ color: '#ccc', marginBottom: '5px' }}>
                                    {`Uploaded by: ${video.uploaderName || 'Unknown'}`}
                                </Typography>
                                <Typography variant="body2" sx={{ color: '#ccc' }}>
                                    {`Date: ${new Date(video.createdAt).toLocaleDateString()}`}
                                </Typography>
                            </CardContent>
                            <IconButton
                                sx={{ position: 'absolute', top: '10px', right: '10px', color: '#ff1744' }}
                                onClick={() => openDeleteDialog(video._id)}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Card>
                    </Grid>
                ))}
            </Grid>
            <Footer>
                <Typography variant="body2">
                    &copy; {new Date().getFullYear()} Mars Reel Media Corp. All rights reserved.
                </Typography>
            </Footer>
            <Dialog
                open={deleteDialogOpen}
                onClose={closeDeleteDialog}
            >
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this video? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDeleteDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={confirmDelete} color="secondary" autoFocus>
                        Confirm Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </ContentFeedContainer>
    );
};

const ContentFeedContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    padding: '2rem',
    backgroundColor: '#1c1c1c',
    color: '#fff',
    minHeight: '100vh',
    position: 'relative',
    paddingBottom: '4rem', // To ensure the footer does not overlap content
});

const Footer = styled(Box)({
    padding: '1rem',
    textAlign: 'center',
    color: '#fff',
    backgroundColor: 'transparent',
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
});

export default ContentFeed;
