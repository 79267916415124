import React, { useState } from 'react';
import { Modal, Box, Typography, Button, TextField, MenuItem } from '@mui/material';

const paymentMethods = [
    { label: 'Bank Transfer', value: 'bank' },
    { label: 'Credit Card', value: 'credit_card' },
    { label: 'PayPal', value: 'paypal' },
];

const PaymentModal = ({ open, handleClose, details }) => {
    const [paymentMethod, setPaymentMethod] = useState(paymentMethods[0].value);
    const [confirming, setConfirming] = useState(false);

    const handlePaymentMethodChange = (event) => {
        setPaymentMethod(event.target.value);
    };

    const handlePayment = () => {
        setConfirming(true);
        setTimeout(() => {
            setConfirming(false);
            handleClose();
            // Payment processing logic goes here
        }, 2000);  // Simulate a delay
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="payment-modal-title"
            aria-describedby="payment-modal-description"
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                borderRadius: 2,
            }}>
                <Typography id="payment-modal-title" variant="h6" component="h2">
                    Payment Details
                </Typography>
                <Typography id="payment-modal-description" sx={{ mt: 2 }}>
                    {details}
                </Typography>
                <TextField
                    select
                    label="Payment Method"
                    value={paymentMethod}
                    onChange={handlePaymentMethodChange}
                    fullWidth
                    sx={{ mt: 3 }}
                >
                    {paymentMethods.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
                <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                    <Button onClick={handleClose} sx={{ mr: 2 }} disabled={confirming}>Cancel</Button>
                    <Button variant="contained" color="primary" onClick={handlePayment} disabled={confirming}>
                        {confirming ? 'Processing...' : 'Proceed to Pay'}
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default PaymentModal;
