import React, { useState } from 'react';
import {
    Box,
    Typography,
    Paper,
    Grid,
    Button,
    Chip,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    TextField,
    InputAdornment,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useParams } from 'react-router-dom';
import { GoogleMap, LoadScript, Marker, StandaloneSearchBox } from '@react-google-maps/api';
import SearchIcon from '@mui/icons-material/Search';

const neighborhoods = [
    { id: 1, city: 'Los Angeles', neighborhood: 'Downtown', teamLeader: 'John Doe', employees: ['Alice Smith', 'Bob Johnson'], lat: 34.0407, lng: -118.2468 },
    { id: 2, city: 'Los Angeles', neighborhood: 'Hollywood', teamLeader: 'Jane Doe', employees: ['Charlie Brown', 'Dana White'], lat: 34.0928, lng: -118.3287 },
    { id: 3, city: 'San Francisco', neighborhood: 'Mission District', teamLeader: 'Mike Ross', employees: ['Eve Black', 'Frank Green'], lat: 37.7599, lng: -122.4148 },
    { id: 4, city: 'San Francisco', neighborhood: 'Nob Hill', teamLeader: 'Rachel Adams', employees: ['Grace Lee', 'Henry Scott'], lat: 37.7930, lng: -122.4167 },
];

const employeesList = [
    'Alice Smith', 'Bob Johnson', 'Charlie Brown', 'Dana White', 'Eve Black',
    'Frank Green', 'Grace Lee', 'Henry Scott', 'Olivia Harris', 'Noah Martin',
    'Sophia Brown', 'Liam Wilson', 'Isabella Clark', 'Lucas King',
    'Emma Thomas', 'Mason Lee'
];

const PageContainer = styled(Box)({
    padding: '2rem',
    backgroundColor: '#1c1c1c',
    minHeight: '100vh',
    color: '#fff',
    paddingBottom: '6rem',
});

const Title = styled(Typography)({
    fontSize: '2rem',
    fontWeight: 'bold',
    marginBottom: '2rem',
});

const LocationCard = styled(Paper)({
    padding: '1.5rem',
    backgroundColor: '#282828',
    color: '#fff',
    borderRadius: '12px',
    boxShadow: '0px 6px 15px rgba(0, 0, 0, 0.4)',
});

const EmployeeChip = styled(Chip)({
    margin: '5px',
    backgroundColor: '#404040',
    color: '#fff',
});

const Footer = styled(Box)({
    padding: '1rem',
    textAlign: 'center',
    color: '#fff',
    backgroundColor: 'transparent',
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
});

const CityOverview = () => {
    const { city } = useParams();
    const decodedCity = decodeURIComponent(city);
    const [locationData, setLocationData] = useState(neighborhoods);
    const [selectedEmployee, setSelectedEmployee] = useState('');
    const [assigningNeighborhood, setAssigningNeighborhood] = useState(null);
    const [mapCenter, setMapCenter] = useState({ lat: 34.0522, lng: -118.2437 }); // Default to Los Angeles
    const [searchBox, setSearchBox] = useState(null);

    const handleAssignEmployee = () => {
        if (selectedEmployee && assigningNeighborhood !== null) {
            const newNeighborhoods = locationData.map((neighborhood) => {
                if (neighborhood.id === assigningNeighborhood) {
                    return { ...neighborhood, employees: [...neighborhood.employees, selectedEmployee] };
                }
                return neighborhood;
            });
            setSelectedEmployee('');
            setAssigningNeighborhood(null);
            setLocationData(newNeighborhoods);
        }
    };

    const handleRemoveEmployee = (neighborhoodId, employeeName) => {
        const newNeighborhoods = locationData.map((neighborhood) => {
            if (neighborhood.id === neighborhoodId) {
                return {
                    ...neighborhood,
                    employees: neighborhood.employees.filter((employee) => employee !== employeeName),
                };
            }
            return neighborhood;
        });
        setLocationData(newNeighborhoods);
    };

    const onLoad = (ref) => setSearchBox(ref);

    const onPlacesChanged = () => {
        const places = searchBox.getPlaces();
        if (places.length > 0) {
            const place = places[0];
            setMapCenter({
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng(),
            });
        }
    };

    const filteredNeighborhoods = locationData.filter(neighborhood => neighborhood.city === decodedCity);

    return (
        <PageContainer>
            <Title>{decodedCity} Overview</Title>
            <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY} libraries={['places']}>
                <StandaloneSearchBox
                    onLoad={onLoad}
                    onPlacesChanged={onPlacesChanged}
                >
                    <TextField
                        variant="outlined"
                        placeholder="Search locations"
                        sx={{
                            width: '100%',
                            marginBottom: '1rem',
                            backgroundColor: '#fff',
                            color: '#000',
                            zIndex: 1,
                            position: 'relative',
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </StandaloneSearchBox>
                <GoogleMap
                    mapContainerStyle={{ width: '100%', height: '400px', marginBottom: '20px' }}
                    center={mapCenter}
                    zoom={12}
                    options={{
                        tilt: 45,
                        heading: 90,
                        zoomControl: true,
                        streetViewControl: false,
                        fullscreenControl: false,
                        disableDefaultUI: true,
                    }}
                >
                    {filteredNeighborhoods.map((neighborhood) => (
                        <Marker
                            key={neighborhood.id}
                            position={{ lat: neighborhood.lat, lng: neighborhood.lng }}
                            title={neighborhood.neighborhood}
                        />
                    ))}
                </GoogleMap>
            </LoadScript>
            <Grid container spacing={4}>
                {filteredNeighborhoods.map((neighborhood) => (
                    <Grid item xs={12} md={6} key={neighborhood.id}>
                        <LocationCard>
                            <Typography variant="h5" gutterBottom>
                                {neighborhood.neighborhood}
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom>
                                Team Leader: {neighborhood.teamLeader}
                            </Typography>
                            <Box sx={{ marginBottom: '1rem' }}>
                                <Typography variant="subtitle2">Employees:</Typography>
                                {neighborhood.employees.map((employee) => (
                                    <EmployeeChip
                                        key={employee}
                                        label={employee}
                                        onDelete={() => handleRemoveEmployee(neighborhood.id, employee)}
                                    />
                                ))}
                            </Box>
                            <FormControl fullWidth variant="outlined" sx={{ marginBottom: '1rem', backgroundColor: '#202020' }}>
                                <InputLabel sx={{ color: '#12c099' }}>Assign Employee</InputLabel>
                                <Select
                                    value={selectedEmployee}
                                    onChange={(e) => setSelectedEmployee(e.target.value)}
                                    label="Assign Employee"
                                    sx={{
                                        color: '#fff',
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#12c099',
                                        },
                                    }}
                                >
                                    {employeesList
                                        .filter((employee) => !neighborhood.employees.includes(employee))
                                        .map((employee) => (
                                            <MenuItem key={employee} value={employee}>
                                                {employee}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    setAssigningNeighborhood(neighborhood.id);
                                    handleAssignEmployee();
                                }}
                                sx={{
                                    backgroundColor: '#12c099',
                                    color: '#fff',
                                    '&:hover': {
                                        backgroundColor: '#0fa77a',
                                    },
                                }}
                                fullWidth
                            >
                                Assign Employee
                            </Button>
                        </LocationCard>
                    </Grid>
                ))}
            </Grid>
            <Footer>
                <Typography variant="body2">
                    &copy; {new Date().getFullYear()} Mars Reel Media Corp. All rights reserved.
                </Typography>
            </Footer>
        </PageContainer>
    );
};

export default CityOverview;
