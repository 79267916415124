import React, { useState } from 'react';
import { Box, TextField, Typography, InputAdornment, Card } from '@mui/material';
import { GoogleMap, LoadScript, Marker, StandaloneSearchBox } from '@react-google-maps/api';
import { Search as SearchIcon } from '@mui/icons-material';

const NeighborhoodsComponent = ({ mapCenter, setMapCenter }) => { // Add setMapCenter prop
    const [searchBox, setSearchBox] = useState(null);

    // Handlers for Google Maps search box
    const onLoad = (ref) => setSearchBox(ref);
    const onPlacesChanged = () => {
        const places = searchBox.getPlaces();
        if (places.length > 0) {
            setMapCenter({
                lat: places[0].geometry.location.lat(),
                lng: places[0].geometry.location.lng(),
            });
        }
    };

    return (
        <Card
            sx={{
                backgroundColor: '#282828',
                color: '#fff',
                padding: '1rem',
                height: '500px',
                boxSizing: 'border-box',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Typography variant="h6" gutterBottom>Your Neighborhoods</Typography>
            <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY} libraries={['places']}>
                <StandaloneSearchBox onLoad={onLoad} onPlacesChanged={onPlacesChanged}>
                    <TextField
                        variant="outlined"
                        placeholder="Search locations"
                        sx={{
                            width: '100%',
                            marginBottom: '1rem',
                            backgroundColor: '#fff',
                            color: '#000',
                            zIndex: 1,
                            position: 'relative',
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </StandaloneSearchBox>
                <Box sx={{ flexGrow: 1 }}>
                    <GoogleMap
                        mapContainerStyle={{ width: '100%', height: '100%' }}
                        center={mapCenter}
                        zoom={14} // More zoomed-in default view
                        options={{
                            tilt: 45,
                            heading: 90,
                            zoomControl: true,
                            streetViewControl: false,
                            fullscreenControl: false,
                            disableDefaultUI: true,
                        }}
                    >
                        <Marker position={mapCenter} />
                    </GoogleMap>
                </Box>
            </LoadScript>
        </Card>
    );
};

export default NeighborhoodsComponent;
