import React, { useState } from 'react';
import {
    Box, Typography, Grid, Paper, Button, Collapse, Chip,
    TextField, InputAdornment
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { GoogleMap, LoadScript, Marker, StandaloneSearchBox } from '@react-google-maps/api';
import SearchIcon from '@mui/icons-material/Search';
import ProfitLossModal from '../components/ProfitLossModal';
import { styled } from '@mui/material/styles';

const locations = [
    {
        city: 'San Francisco',
        state: 'CA',
        country: 'United States',
        latitude: 37.7749,
        longitude: -122.4194,
        coverage: 80,
        neighborhoods: ['Mission District', 'SoMa', 'Nob Hill'],
    },
    {
        city: 'Los Angeles',
        state: 'CA',
        country: 'United States',
        latitude: 34.0522,
        longitude: -118.2437,
        coverage: 75,
        neighborhoods: ['Hollywood', 'Beverly Hills', 'Downtown'],
    },
    {
        city: 'New York',
        state: 'NY',
        country: 'United States',
        latitude: 40.7128,
        longitude: -74.0060,
        coverage: 85,
        neighborhoods: ['Manhattan', 'Brooklyn', 'Queens'],
    },
    {
        city: 'Miami',
        state: 'FL',
        country: 'United States',
        latitude: 25.7617,
        longitude: -80.1918,
        coverage: 65,
        neighborhoods: ['Downtown', 'Little Havana', 'Wynwood'],
    },
    {
        city: 'Austin',
        state: 'TX',
        country: 'United States',
        latitude: 30.2672,
        longitude: -97.7431,
        coverage: 90,
        neighborhoods: ['Downtown', 'South Congress', 'East Austin'],
    },
];

const mapContainerStyle = {
    width: '100%',
    height: '400px',
    marginBottom: '20px',
};

const Footer = styled(Box)({
    padding: '1rem',
    textAlign: 'center',
    color: '#fff',
    backgroundColor: 'transparent',
    marginTop: 'auto',
    width: '100%',
});

const Locations = () => {
    const [expanded, setExpanded] = useState({});
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [selectedNeighborhood, setSelectedNeighborhood] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [mapCenter, setMapCenter] = useState({ lat: 37.7749, lng: -122.4194 });
    const [searchBox, setSearchBox] = useState(null);

    const handleExpandClick = (index) => {
        setExpanded((prev) => ({ ...prev, [index]: !prev[index] }));
    };

    const handleProfitLossClick = (location, neighborhood = null) => {
        setSelectedLocation(location);
        setSelectedNeighborhood(neighborhood);
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
        setSelectedNeighborhood(null);
    };

    const onLoad = (ref) => setSearchBox(ref);

    const onPlacesChanged = () => {
        const places = searchBox.getPlaces();
        if (places.length > 0) {
            const place = places[0];
            setMapCenter({
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng(),
            });
        }
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', backgroundColor: '#1c1c1c', color: '#fff' }}>
            <Box sx={{ flex: 1, padding: '2rem' }}>
                <Typography variant="h4" gutterBottom>Locations</Typography>
                <Grid container spacing={3} sx={{ marginBottom: '2rem' }}>
                    <Grid item xs={12} md={8}>
                        <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY} libraries={['places']}>
                            <StandaloneSearchBox
                                onLoad={onLoad}
                                onPlacesChanged={onPlacesChanged}
                            >
                                <TextField
                                    variant="outlined"
                                    placeholder="Search locations"
                                    sx={{
                                        width: '100%',
                                        marginBottom: '1rem',
                                        backgroundColor: '#fff',
                                        color: '#000',
                                        zIndex: 1,
                                        position: 'relative',
                                    }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </StandaloneSearchBox>
                            <Box sx={{ height: '400px', width: '100%' }}>
                                <GoogleMap
                                    mapContainerStyle={mapContainerStyle}
                                    center={mapCenter}
                                    zoom={4}
                                    options={{
                                        tilt: 45,
                                        heading: 90,
                                        zoomControl: true,
                                        streetViewControl: false,
                                        fullscreenControl: false,
                                        disableDefaultUI: true,
                                    }}
                                >
                                    {locations.map((location, index) => (
                                        <Marker
                                            key={index}
                                            position={{ lat: location.latitude, lng: location.longitude }}
                                            title={location.city}
                                        />
                                    ))}
                                </GoogleMap>
                            </Box>
                        </LoadScript>
                    </Grid>
                </Grid>

                <Typography variant="h5" gutterBottom>Current Locations</Typography>
                <Grid container spacing={3}>
                    {locations.map((location, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                            <Paper
                                sx={{
                                    padding: '1rem',
                                    backgroundColor: '#282828',
                                    color: '#fff',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                    position: 'relative',
                                    minHeight: '200px',
                                }}
                            >
                                <Box sx={{ marginBottom: '1rem' }}>
                                    <Typography variant="h6">{location.city}, {location.state}</Typography>
                                    <Box
                                        sx={{
                                            backgroundColor: '#12c099',
                                            color: '#fff',
                                            padding: '0.25rem 0.5rem',
                                            borderRadius: '4px',
                                            fontSize: '0.875rem',
                                            fontWeight: 'bold',
                                            display: 'inline-block',
                                        }}
                                    >
                                        {location.coverage}% Coverage
                                    </Box>
                                    <Typography variant="body2" sx={{ marginTop: '0.5rem' }}>{location.country}</Typography>
                                </Box>
                                <Button
                                    onClick={() => handleProfitLossClick(location)}
                                    sx={{ color: '#12c099', alignSelf: 'flex-start' }}
                                >
                                    Profit/Loss
                                </Button>
                                <Button
                                    onClick={() => handleExpandClick(index)}
                                    sx={{ color: '#12c099', alignSelf: 'flex-start' }}
                                    endIcon={expanded[index] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                >
                                    {expanded[index] ? 'Hide Neighborhoods' : 'Show Neighborhoods'}
                                </Button>
                                <Collapse in={expanded[index]} timeout="auto" unmountOnExit>
                                    <Box sx={{ mt: 2, display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
                                        {location.neighborhoods.map((neighborhood, nIndex) => (
                                            <Chip
                                                key={nIndex}
                                                label={neighborhood}
                                                sx={{ backgroundColor: '#404040', color: '#fff', cursor: 'pointer' }}
                                                onClick={() => handleProfitLossClick(location, neighborhood)}
                                            />
                                        ))}
                                    </Box>
                                </Collapse>
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
                {selectedLocation && (
                    <ProfitLossModal
                        open={modalOpen}
                        onClose={handleModalClose}
                        location={{ ...selectedLocation, neighborhood: selectedNeighborhood }}
                    />
                )}
            </Box>

            {/* Footer Section */}
            <Footer>
                <Typography variant="body2">
                    &copy; {new Date().getFullYear()} Mars Reel Media Corp. All rights reserved.
                </Typography>
            </Footer>
        </Box>
    );
};

export default Locations;
