import React from 'react';
import { Box, CssBaseline, Typography, Paper, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import QuotasComponent from '../components/dashboard-components/QuotasComponent'; // Correct path

const Root = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    backgroundColor: '#1c1c1c',
    color: '#fff',
    boxSizing: 'border-box',
    overflowX: 'hidden',
});

const CustomPaper = styled(Paper)({
    padding: '2rem',
    backgroundColor: '#202020',
    color: '#fff',
    borderRadius: '12px',
    boxSizing: 'border-box',
    minHeight: '180px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
});

const WidePaper = styled(CustomPaper)({
    minHeight: '350px',
    justifyContent: 'flex-start',
});

const FlexBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    flexShrink: 0,
});

const Footer = styled(Box)({
    padding: '1rem',
    textAlign: 'center',
    color: '#fff',
    backgroundColor: 'transparent', // Matches the rest of the page background
    marginTop: 'auto',
    width: '100%',
});

const Dashboard = () => {
    return (
        <Root>
            <CssBaseline />
            <Box sx={{ flex: 1, padding: '2rem' }}>
                <Box sx={{ marginBottom: '2.5rem' }}>
                    <Typography variant="h3" align="center" gutterBottom sx={{ fontWeight: 'bold' }}>
                        Dashboard Overview
                    </Typography>
                </Box>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={6} md={4}>
                        <CustomPaper>
                            <FlexBox>
                                <Typography variant="h5" sx={{ marginBottom: '1rem', fontWeight: '500' }}>
                                    Recurring Members
                                </Typography>
                                <Typography variant="h3" sx={{ color: '#12c099', fontWeight: 'bold' }}>1500</Typography>
                            </FlexBox>
                        </CustomPaper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <CustomPaper>
                            <FlexBox>
                                <Typography variant="h5" sx={{ marginBottom: '1rem', fontWeight: '500' }}>
                                    PayGo Members
                                </Typography>
                                <Typography variant="h3" sx={{ color: '#12c099', fontWeight: 'bold' }}>500</Typography>
                            </FlexBox>
                        </CustomPaper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <CustomPaper>
                            <FlexBox>
                                <Typography variant="h5" sx={{ marginBottom: '1rem', fontWeight: '500' }}>
                                    Locations
                                </Typography>
                                <Typography variant="h3" sx={{ color: '#12c099', fontWeight: 'bold' }}>25</Typography>
                            </FlexBox>
                        </CustomPaper>
                    </Grid>

                    {/* Add the quota grid boxes here */}
                    <Grid item xs={12} sm={6} md={4}>
                        <QuotasComponent quotaType="monthly" />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <QuotasComponent quotaType="weekly" />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <QuotasComponent quotaType="daily" />
                    </Grid>

                    <Grid item xs={12} md={8}>
                        <WidePaper>
                            <Typography variant="h5" gutterBottom sx={{ fontWeight: '500' }}>
                                From Feed
                            </Typography>
                            <Box sx={{ maxHeight: '300px', overflowY: 'auto', padding: '1rem', flexGrow: 1 }}>
                                <Typography variant="body1">Latest content uploaded...</Typography>
                                {/* Add more content here */}
                            </Box>
                        </WidePaper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <CustomPaper>
                            <FlexBox>
                                <Typography variant="h5" sx={{ marginBottom: '1rem', fontWeight: '500' }}>
                                    Monthly Recurring Revenue
                                </Typography>
                                <Typography
                                    variant="h3"
                                    sx={{
                                        color: '#12c099',
                                        fontWeight: 'bold',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        maxWidth: '100%', // Ensures it shrinks within the box
                                    }}
                                >
                                    $12,000
                                </Typography>
                            </FlexBox>
                        </CustomPaper>
                    </Grid>
                </Grid>
            </Box>

            {/* Footer Section */}
            <Footer>
                <Typography variant="body2">
                    &copy; {new Date().getFullYear()} Mars Reel Media Corp. All rights reserved.
                </Typography>
            </Footer>
        </Root>
    );
};

export default Dashboard;
