import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, Avatar, Grid, Paper, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import axios from 'axios';

const OverviewContainer = styled(Box)({
    padding: '2rem',
    backgroundColor: '#1c1c1c',
    color: '#fff',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
    paddingBottom: '6rem', // Ensure there's enough space for the footer
});

const SectionTitle = styled(Typography)({
    color: '#12c099',
    fontWeight: 'bold',
    marginBottom: '1rem',
});

const Footer = styled(Box)({
    padding: '1rem',
    textAlign: 'center',
    color: '#fff',
    backgroundColor: 'transparent',
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
});

const WaitlistMember = () => {
    const { id } = useParams(); // Get the waitlist user's document ID from the URL
    const [waitlistUser, setWaitlistUser] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Fetch waitlist user data by document ID
        const fetchWaitlistUser = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/waitlist-member/${id}`);
                setWaitlistUser(response.data);
            } catch (error) {
                console.error('Error fetching waitlist user:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchWaitlistUser();
    }, [id]);

    if (loading) {
        return <Typography variant="h6" color="inherit">Loading...</Typography>;
    }

    if (!waitlistUser) {
        return <Typography variant="h6" color="inherit">Waitlist member not found.</Typography>;
    }

    return (
        <OverviewContainer>
            <Typography variant="h4" gutterBottom>Waitlist Overview</Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={4} md={3}>
                    {/* Display Avatar if available */}
                    <Avatar src={waitlistUser.avatar || ''} sx={{ width: 120, height: 120 }} />
                </Grid>
                <Grid item xs={12} sm={8} md={9}>
                    <Typography variant="h5">{`${waitlistUser.firstName} ${waitlistUser.lastName}`}</Typography>
                    <Typography variant="body1">Email: {waitlistUser.email}</Typography>
                    <Typography variant="body1">Phone: {waitlistUser.phone}</Typography>
                    <Typography variant="body1">Street: {waitlistUser.street}</Typography>
                    <Typography variant="body1">City: {waitlistUser.city}</Typography>
                    <Typography variant="body1">State: {waitlistUser.state}</Typography>
                    <Typography variant="body1">Zip Code: {waitlistUser.zipCode}</Typography>
                    <Typography variant="body1">Country: {waitlistUser.country}</Typography>
                    <Typography variant="body1">Date Joined: {new Date(waitlistUser.dateJoined).toLocaleDateString()}</Typography>
                    <Typography variant="body1">Gender: {waitlistUser.gender}</Typography>
                </Grid>
            </Grid>

            <Paper sx={{ padding: '1.5rem', backgroundColor: '#202020', color: '#fff', borderRadius: '8px', marginTop: '2rem' }}>
                <SectionTitle variant="h6">Actions</SectionTitle>
                <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center', flexWrap: 'wrap' }}>
                    <Button variant="contained" color="primary" sx={{ backgroundColor: '#12c099', color: '#fff' }}>
                        Invite to Academy
                    </Button>
                    <Button variant="contained" color="secondary" sx={{ backgroundColor: '#c0392b', color: '#fff' }}>
                        Remove from Waitlist
                    </Button>
                </Box>
            </Paper>
            <Footer>
                <Typography variant="body2">
                    &copy; {new Date().getFullYear()} Mars Reel Media Corp. All rights reserved.
                </Typography>
            </Footer>
        </OverviewContainer>
    );
};

export default WaitlistMember;
