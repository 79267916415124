import React, { useState } from 'react';
import { Typography, Box, Grid, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    LineChart,
    Line,
    ResponsiveContainer,
} from 'recharts';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const CustomPaper = styled(Paper)({
    padding: '1.5rem',
    backgroundColor: '#202020',
    color: '#fff',
    textAlign: 'center',
    borderRadius: '8px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
    flex: 1,
    margin: '10px',
    boxSizing: 'border-box',
    minHeight: '350px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
});

const Footer = styled(Box)({
    padding: '1rem',
    textAlign: 'center',
    color: '#fff',
    backgroundColor: 'transparent',
    marginTop: 'auto',
});

const data = [
    { name: 'Jan', revenue: 4000, cost: 2400, profit: 1600, athletes: 24, utilization: 80, locations: 5, sessions: 20, cancellations: 2, coachRating: 4.5 },
    { name: 'Feb', revenue: 3000, cost: 1398, profit: 1602, athletes: 18, utilization: 75, locations: 4, sessions: 15, cancellations: 1, coachRating: 4.7 },
    { name: 'Mar', revenue: 2000, cost: 980, profit: 1020, athletes: 20, utilization: 90, locations: 6, sessions: 22, cancellations: 0, coachRating: 4.6 },
    { name: 'Apr', revenue: 2780, cost: 3908, profit: -1128, athletes: 23, utilization: 85, locations: 7, sessions: 25, cancellations: 3, coachRating: 4.8 },
    { name: 'May', revenue: 1890, cost: 4800, profit: -2910, athletes: 19, utilization: 70, locations: 8, sessions: 30, cancellations: 4, coachRating: 4.2 },
    { name: 'Jun', revenue: 2390, cost: 3800, profit: -1410, athletes: 22, utilization: 95, locations: 6, sessions: 28, cancellations: 2, coachRating: 4.9 },
    { name: 'Jul', revenue: 3490, cost: 4300, profit: -810, athletes: 21, utilization: 88, locations: 9, sessions: 35, cancellations: 1, coachRating: 4.3 },
];

const athleteData = [
    { name: 'Jan', revenue: 400, cost: 240 },
    { name: 'Feb', revenue: 300, cost: 139 },
    { name: 'Mar', revenue: 200, cost: 98 },
    { name: 'Apr', revenue: 278, cost: 39 },
    { name: 'May', revenue: 189, cost: 48 },
    { name: 'Jun', revenue: 239, cost: 38 },
    { name: 'Jul', revenue: 349, cost: 43 },
];

const Financials = () => {
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', backgroundColor: '#1c1c1c', color: '#fff' }}>
            <Box sx={{ flex: 1, padding: '2rem' }}>
                <Typography variant="h4" gutterBottom>Performance & Financial Ratios</Typography>
                <Grid container spacing={2} sx={{ marginBottom: '20px' }}>
                    <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="body1" gutterBottom>Start Date</Typography>
                        <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            selectsStart
                            startDate={startDate}
                            endDate={endDate}
                            placeholderText="Start Date"
                            customInput={<input style={{ backgroundColor: '#202020', color: '#fff', border: 'none', padding: '10px', borderRadius: '4px', width: '100%' }} />}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="body1" gutterBottom>End Date</Typography>
                        <DatePicker
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            selectsEnd
                            startDate={startDate}
                            endDate={endDate}
                            placeholderText="End Date"
                            minDate={startDate}
                            customInput={<input style={{ backgroundColor: '#202020', color: '#fff', border: 'none', padding: '10px', borderRadius: '4px', width: '100%' }} />}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3} sx={{ mt: 3 }}>
                    {/* Revenue per Session */}
                    <Grid item xs={12} md={6}>
                        <CustomPaper>
                            <Typography variant="h5">Revenue per Session</Typography>
                            <ResponsiveContainer width="100%" height={250}>
                                <BarChart data={data}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Bar dataKey="revenue" fill="#8884d8" />
                                </BarChart>
                            </ResponsiveContainer>
                        </CustomPaper>
                    </Grid>
                    {/* Profit per Session */}
                    <Grid item xs={12} md={6}>
                        <CustomPaper>
                            <Typography variant="h5">Profit per Session</Typography>
                            <ResponsiveContainer width="100%" height={250}>
                                <BarChart data={data}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Bar dataKey="profit" fill="#82ca9d" />
                                </BarChart>
                            </ResponsiveContainer>
                        </CustomPaper>
                    </Grid>
                    {/* Revenue vs Cost per Athlete */}
                    <Grid item xs={12} md={6}>
                        <CustomPaper>
                            <Typography variant="h5">Revenue vs Cost per Athlete</Typography>
                            <ResponsiveContainer width="100%" height={250}>
                                <BarChart data={athleteData}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Bar dataKey="revenue" fill="#8884d8" />
                                    <Bar dataKey="cost" fill="#82ca9d" />
                                </BarChart>
                            </ResponsiveContainer>
                        </CustomPaper>
                    </Grid>
                    {/* Utilization Rate */}
                    <Grid item xs={12} md={6}>
                        <CustomPaper>
                            <Typography variant="h5">Utilization Rate</Typography>
                            <ResponsiveContainer width="100%" height={250}>
                                <LineChart data={data}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" />
                                    <YAxis tickFormatter={(tick) => `${tick}%`} />
                                    <Tooltip />
                                    <Legend />
                                    <Line type="monotone" dataKey="utilization" stroke="#8884d8" />
                                </LineChart>
                            </ResponsiveContainer>
                        </CustomPaper>
                    </Grid>
                    {/* Profit Margins */}
                    <Grid item xs={12} md={6}>
                        <CustomPaper>
                            <Typography variant="h5">Profit Margins</Typography>
                            <ResponsiveContainer width="100%" height={250}>
                                <LineChart data={data}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Line type="monotone" dataKey="profit" stroke="#82ca9d" />
                                </LineChart>
                            </ResponsiveContainer>
                        </CustomPaper>
                    </Grid>
                    {/* Cost per Session */}
                    <Grid item xs={12} md={6}>
                        <CustomPaper>
                            <Typography variant="h5">Cost per Session</Typography>
                            <ResponsiveContainer width="100%" height={250}>
                                <BarChart data={data}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Bar dataKey="cost" fill="#82ca9d" />
                                </BarChart>
                            </ResponsiveContainer>
                        </CustomPaper>
                    </Grid>
                    {/* Total Locations */}
                    <Grid item xs={12} md={6}>
                        <CustomPaper>
                            <Typography variant="h5">Total Locations</Typography>
                            <ResponsiveContainer width="100%" height={250}>
                                <BarChart data={data}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Bar dataKey="locations" fill="#8884d8" />
                                </BarChart>
                            </ResponsiveContainer>
                        </CustomPaper>
                    </Grid>
                    {/* Total Sessions */}
                    <Grid item xs={12} md={6}>
                        <CustomPaper>
                            <Typography variant="h5">Total Sessions</Typography>
                            <ResponsiveContainer width="100%" height={250}>
                                <BarChart data={data}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Bar dataKey="sessions" fill="#8884d8" />
                                </BarChart>
                            </ResponsiveContainer>
                        </CustomPaper>
                    </Grid>
                    {/* Cancellations */}
                    <Grid item xs={12} md={6}>
                        <CustomPaper>
                            <Typography variant="h5">Cancellations</Typography>
                            <ResponsiveContainer width="100%" height={250}>
                                <BarChart data={data}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Bar dataKey="cancellations" fill="#8884d8" />
                                </BarChart>
                            </ResponsiveContainer>
                        </CustomPaper>
                    </Grid>
                    {/* Average Coach Rating */}
                    <Grid item xs={12} md={6}>
                        <CustomPaper>
                            <Typography variant="h5">Average Coach Rating</Typography>
                            <ResponsiveContainer width="100%" height={250}>
                                <LineChart data={data}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" />
                                    <YAxis domain={[0, 5]} tickFormatter={(tick) => `${tick}`} />
                                    <Tooltip />
                                    <Legend />
                                    <Line type="monotone" dataKey="coachRating" stroke="#8884d8" />
                                </LineChart>
                            </ResponsiveContainer>
                        </CustomPaper>
                    </Grid>
                </Grid>
            </Box>
            <Footer>
                <Typography variant="body2">
                    &copy; {new Date().getFullYear()} Mars Reel Media Corp. All rights reserved.
                </Typography>
            </Footer>
        </Box>
    );
};

export default Financials;
