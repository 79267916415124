import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import axios from 'axios';

const QuotasComponent = ({ quotaType, address = {}, sports = [], employeeId }) => {
    const [quota, setQuota] = useState(0);
    const [userCount, setUserCount] = useState(0);
    const [employeeQuota, setEmployeeQuota] = useState(0);
    const [regionUserCount, setRegionUserCount] = useState(0);
    const [teamMembers, setTeamMembers] = useState(0); // Number of employees in the region
    const maxSessionsPerEmployee = 160; // Max sessions an employee can organize

    const { city = 'Unknown City', state = 'Unknown State', country = 'Unknown Country' } = address; // Destructure with default values

    useEffect(() => {
        const fetchQuotaAndUserCount = async () => {
            try {
                // Fetch the total number of users globally
                const userResponse = await axios.get('/api/users/count');
                const totalUsers = userResponse.data.totalUsers;
                setUserCount(totalUsers);

                // Fetch the number of users for the specific region (using address) and sport
                const regionUserResponse = await axios.get(`/api/users/count?city=${city}&state=${state}&country=${country}&sports=${sports}`);
                const regionUsers = regionUserResponse.data.regionUsers;
                setRegionUserCount(regionUsers);

                // Calculate the number of groups (each group contains 35 users)
                const globalGroupsOf35 = Math.ceil(totalUsers / 35);
                const regionGroupsOf35 = Math.ceil(regionUsers / 35);

                // Fetch the number of team members for the specific region and sport
                const teamResponse = await axios.get(`/api/teams?city=${city}&state=${state}&country=${country}&sports=${sports}`);
                const totalTeamMembers = teamResponse.data.teamMembers;
                setTeamMembers(totalTeamMembers);

                // Calculate the quota based on quotaType (monthly, weekly, daily) and number of groups
                let globalQuota;
                let regionQuota;
                switch (quotaType) {
                    case 'monthly':
                        globalQuota = globalGroupsOf35 * 4; // 4 sessions per group per month globally
                        regionQuota = regionGroupsOf35 * 4; // 4 sessions per group per month for the region
                        break;
                    case 'weekly':
                        globalQuota = (globalGroupsOf35 * 4) / 4; // Weekly global quota
                        regionQuota = (regionGroupsOf35 * 4) / 4; // Weekly regional quota
                        break;
                    case 'daily':
                        globalQuota = (globalGroupsOf35 * 4) / 30; // Daily global quota
                        regionQuota = (regionGroupsOf35 * 4) / 30; // Daily regional quota
                        break;
                    default:
                        globalQuota = 0;
                        regionQuota = 0;
                        break;
                }

                setQuota(globalQuota);

                // Calculate employee-specific quota
                const totalRegionQuota = Math.min(regionQuota, totalTeamMembers * maxSessionsPerEmployee);
                const individualQuota = totalRegionQuota / totalTeamMembers;
                setEmployeeQuota(Math.min(individualQuota, maxSessionsPerEmployee));

            } catch (error) {
                console.error(`Error fetching ${quotaType} quota or user count:`, error);
            }
        };

        fetchQuotaAndUserCount();  // Fetch the user count and calculate the quota on component mount
    }, [quotaType, city, state, country, sports]);

    return (
        <Card sx={{ backgroundColor: '#202020', color: '#fff', padding: '2rem', borderRadius: '12px', minHeight: '180px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CardContent>
                <Typography variant="h6">
                    {quotaType.charAt(0).toUpperCase() + quotaType.slice(1)} Session Quota
                </Typography>
                <Typography variant="h4" sx={{ color: '#12c099', fontWeight: 'bold' }}>
                    {employeeQuota.toFixed(2)} {/* Employee quota displayed */}
                </Typography>
                <Typography variant="body2">
                    Based on {regionUserCount} users in {city}, {state} for {sports.join(', ')}.
                </Typography>
                <Typography variant="body2">
                    Global Quota: {quota.toFixed(2)} | Region Quota: {(regionUserCount * 4 / 35).toFixed(2)}
                </Typography>
            </CardContent>
        </Card>
    );
};

export default QuotasComponent;
