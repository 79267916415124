import React, { useState } from 'react';
import { Box, Typography, Paper, Grid, Button, Chip, Select, MenuItem, FormControl, InputLabel, TextField, InputAdornment } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useParams } from 'react-router-dom';
import { GoogleMap, LoadScript, Marker, StandaloneSearchBox } from '@react-google-maps/api';
import SearchIcon from '@mui/icons-material/Search';

const cities = [
    { id: 1, state: 'California', city: 'Los Angeles', teamLeader: 'John Doe', employees: ['Alice Smith', 'Bob Johnson'], lat: 34.0522, lng: -118.2437 },
    { id: 2, state: 'California', city: 'San Francisco', teamLeader: 'Jane Doe', employees: ['Charlie Brown', 'Dana White'], lat: 37.7749, lng: -122.4194 },
    { id: 3, state: 'New York', city: 'New York City', teamLeader: 'Mike Ross', employees: ['Eve Black', 'Frank Green'], lat: 40.7128, lng: -74.0060 },
    { id: 4, state: 'Florida', city: 'Miami', teamLeader: 'Rachel Adams', employees: ['Grace Lee', 'Henry Scott'], lat: 25.7617, lng: -80.1918 },
];

const employeesList = [
    'Alice Smith', 'Bob Johnson', 'Charlie Brown', 'Dana White', 'Eve Black',
    'Frank Green', 'Grace Lee', 'Henry Scott', 'Olivia Harris', 'Noah Martin',
    'Sophia Brown', 'Liam Wilson', 'Isabella Clark', 'Lucas King',
    'Emma Thomas', 'Mason Lee'
];

const PageContainer = styled(Box)({
    padding: '2rem',
    backgroundColor: '#1c1c1c',
    minHeight: '100vh',
    color: '#fff',
    paddingBottom: '6rem', // Ensure there's enough space for the footer
});

const Title = styled(Typography)({
    fontSize: '2rem',
    fontWeight: 'bold',
    marginBottom: '2rem',
});

const LocationCard = styled(Paper)({
    padding: '1.5rem',
    backgroundColor: '#282828',
    color: '#fff',
    borderRadius: '12px',
    boxShadow: '0px 6px 15px rgba(0, 0, 0, 0.4)',
});

const EmployeeChip = styled(Chip)({
    margin: '5px',
    backgroundColor: '#404040',
    color: '#fff',
});

const Footer = styled(Box)({
    padding: '1rem',
    textAlign: 'center',
    color: '#fff',
    backgroundColor: 'transparent',
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
});

const mapContainerStyle = {
    width: '100%',
    height: '400px',
    marginBottom: '20px',
};

const StateOverview = () => {
    const { state } = useParams();
    const decodedState = decodeURIComponent(state);
    const [locationData, setLocationData] = useState(cities);
    const [selectedEmployee, setSelectedEmployee] = useState('');
    const [assigningCity, setAssigningCity] = useState(null);
    const [mapCenter, setMapCenter] = useState({ lat: 36.7783, lng: -119.4179 });

    const handleAssignEmployee = () => {
        if (selectedEmployee && assigningCity !== null) {
            const newCities = locationData.map((city) => {
                if (city.id === assigningCity) {
                    return { ...city, employees: [...city.employees, selectedEmployee] };
                }
                return city;
            });
            setSelectedEmployee('');
            setAssigningCity(null);
            setLocationData(newCities);
        }
    };

    const handleRemoveEmployee = (cityId, employeeName) => {
        const newCities = locationData.map((city) => {
            if (city.id === cityId) {
                return {
                    ...city,
                    employees: city.employees.filter((employee) => employee !== employeeName),
                };
            }
            return city;
        });
        setLocationData(newCities);
    };

    const filteredCities = locationData.filter(city => city.state === decodedState);

    return (
        <PageContainer>
            <Title>{decodedState} Overview</Title>
            <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY} libraries={['places']}>
                <StandaloneSearchBox onLoad={(ref) => {}} onPlacesChanged={() => {}}>
                    <TextField
                        variant="outlined"
                        placeholder="Search locations"
                        sx={{
                            width: '100%',
                            marginBottom: '1rem',
                            backgroundColor: '#fff',
                            color: '#000',
                            zIndex: 1,
                            position: 'relative',
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </StandaloneSearchBox>
                <GoogleMap
                    mapContainerStyle={mapContainerStyle}
                    center={mapCenter}
                    zoom={6}
                    options={{
                        tilt: 45,
                        heading: 90,
                        zoomControl: true,
                        streetViewControl: false,
                        fullscreenControl: false,
                        disableDefaultUI: true,
                    }}
                >
                    {filteredCities.map((city) => (
                        <Marker key={city.id} position={{ lat: city.lat, lng: city.lng }} title={city.city} />
                    ))}
                </GoogleMap>
            </LoadScript>
            <Grid container spacing={4}>
                {filteredCities.map((city) => (
                    <Grid item xs={12} md={6} key={city.id}>
                        <LocationCard>
                            <Typography variant="h5" gutterBottom>
                                {city.city}
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom>
                                Team Leader: {city.teamLeader}
                            </Typography>
                            <Box sx={{ marginBottom: '1rem' }}>
                                <Typography variant="subtitle2">Employees:</Typography>
                                {city.employees.map((employee) => (
                                    <EmployeeChip key={employee} label={employee} onDelete={() => handleRemoveEmployee(city.id, employee)} />
                                ))}
                            </Box>
                            <FormControl fullWidth variant="outlined" sx={{ marginBottom: '1rem', backgroundColor: '#202020' }}>
                                <InputLabel sx={{ color: '#12c099' }}>Assign Employee</InputLabel>
                                <Select
                                    value={selectedEmployee}
                                    onChange={(e) => setSelectedEmployee(e.target.value)}
                                    label="Assign Employee"
                                    sx={{
                                        color: '#fff',
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#12c099',
                                        },
                                    }}
                                >
                                    {employeesList.filter((employee) => !city.employees.includes(employee)).map((employee) => (
                                        <MenuItem key={employee} value={employee}>
                                            {employee}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    setAssigningCity(city.id);
                                    handleAssignEmployee();
                                }}
                                sx={{
                                    backgroundColor: '#12c099',
                                    color: '#fff',
                                    '&:hover': {
                                        backgroundColor: '#0fa77a',
                                    },
                                }}
                                fullWidth
                            >
                                Assign Employee
                            </Button>
                        </LocationCard>
                    </Grid>
                ))}
            </Grid>
            <Footer>
                <Typography variant="body2">
                    &copy; {new Date().getFullYear()} Mars Reel Media Corp. All rights reserved.
                </Typography>
            </Footer>
        </PageContainer>
    );
};

export default StateOverview;
