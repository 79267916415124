import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography, LinearProgress } from '@mui/material';
import axios from 'axios';

const ProgressComponent = () => {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const fetchProgress = async () => {
            try {
                console.log('Fetching progress data');
                const response = await axios.get('/api/quotas/progress');
                console.log('Progress data:', response.data);
                setProgress(response.data.weeklyProgressPercentage);
            } catch (error) {
                console.error('Error fetching progress:', error);
            }
        };
        fetchProgress();
    }, []);

    console.log('Rendering ProgressComponent with progress:', progress);

    return (
        <Card sx={{ backgroundColor: '#282828', color: '#fff', padding: '1rem' }}>
            <CardContent>
                <Typography variant="h6">Progress This Week</Typography>
                <Typography variant="body1">You have organized {progress}% of your sessions this week.</Typography>
                <LinearProgress variant="determinate" value={progress} sx={{ height: '10px', borderRadius: '5px', backgroundColor: '#404040' }} />
            </CardContent>
        </Card>
    );
};

export default ProgressComponent;
