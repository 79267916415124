import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Avatar,
    TextField,
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Paper,
    Pagination,
    Typography,
    IconButton,
    Dialog,
    DialogTitle,
    DialogActions,
    Button,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';

// Define styled components
const CoachesContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    padding: '1rem',
    backgroundColor: '#1c1c1c',
    minHeight: '100vh',
});

const HeaderContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
    flexWrap: 'wrap',
    '@media (max-width: 768px)': {
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
});

const Title = styled(Typography)({
    fontSize: '1.8rem',
    color: '#fff',
    fontWeight: 'bold',
    '@media (max-width: 768px)': {
        fontSize: '1.5rem',
    },
});

const SearchBar = styled(TextField)({
    marginBottom: '20px',
    width: '100%',
    backgroundColor: '#202020',
    borderRadius: '8px',
    color: '#fff',
    '& .MuiInputBase-root': {
        color: '#fff',
        fontSize: '0.9rem',
    },
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#12c099',
    },
    '& .MuiInputLabel-root': {
        color: '#12c099',
        fontSize: '0.9rem',
    },
});

const StyledTableContainer = styled(TableContainer)({
    backgroundColor: '#202020',
    borderRadius: '12px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
    overflowX: 'auto',
    '@media (max-width: 768px)': {
        width: '100%',
    },
});

const CompactTableCell = styled(TableCell)({
    padding: '16px 20px',
    fontSize: '1rem',
    '@media (max-width: 768px)': {
        display: 'block',
        width: '100%',
        padding: '12px 0',
        fontSize: '0.9rem',
        textAlign: 'left',
    },
    color: '#fff',
});

const CompactTableRow = styled(TableRow)({
    '@media (max-width: 768px)': {
        display: 'block',
        width: '100%',
        marginBottom: '20px',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
        borderRadius: '8px',
        backgroundColor: '#202020',
        overflow: 'hidden',
    },
});

const PaginationBox = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
    '@media (max-width: 768px)': {
        marginTop: '10px',
    },
});

const Footer = styled(Box)({
    padding: '1rem',
    textAlign: 'center',
    color: '#fff',
    backgroundColor: 'transparent',
    marginTop: 'auto',
    width: '100%',
});

const Coaches = () => {
    const [coaches, setCoaches] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [page, setPage] = useState(1);
    const rowsPerPage = 5;
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');
    const [loading, setLoading] = useState(true);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [selectedCoachFirebaseUid, setSelectedCoachFirebaseUid] = useState(null);

    useEffect(() => {
        const fetchCoaches = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/coaches`);
                setCoaches(response.data);
            } catch (error) {
                console.error('Error fetching coaches:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchCoaches();
    }, []);

    const handleSortRequest = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const openDeleteDialogHandler = (firebaseUid) => {
        setSelectedCoachFirebaseUid(firebaseUid);
        setOpenDeleteDialog(true);
    };

    const closeDeleteDialog = () => {
        setOpenDeleteDialog(false);
        setSelectedCoachFirebaseUid(null);
    };

    const confirmDelete = async () => {
        try {
            await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/delete-coach/${selectedCoachFirebaseUid}`);
            setCoaches(coaches.filter((coach) => coach.firebaseUid !== selectedCoachFirebaseUid));
            closeDeleteDialog();
        } catch (error) {
            console.error('Error deleting coach:', error);
        }
    };

    const filteredCoaches = coaches.filter((coach) =>
        coach.name?.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const sortedCoaches = filteredCoaches.sort((a, b) => {
        if (order === 'asc') {
            return a[orderBy]?.localeCompare(b[orderBy]);
        }
        return b[orderBy]?.localeCompare(a[orderBy]);
    });

    const paginatedCoaches = sortedCoaches.slice((page - 1) * rowsPerPage, page * rowsPerPage);

    return (
        <CoachesContainer>
            <HeaderContainer>
                <Title variant="h4" gutterBottom>Coaches</Title>
                <SearchBar
                    variant="outlined"
                    label="Search Coaches"
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
            </HeaderContainer>
            {loading ? (
                <Typography variant="h6" style={{ color: '#fff' }}>
                    Loading...
                </Typography>
            ) : (
                <StyledTableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <CompactTableCell>
                                    <TableSortLabel
                                        active={orderBy === 'name'}
                                        direction={orderBy === 'name' ? order : 'asc'}
                                        onClick={() => handleSortRequest('name')}
                                        style={{ color: '#12c099' }}
                                    >
                                        Name
                                    </TableSortLabel>
                                </CompactTableCell>
                                <CompactTableCell>
                                    <TableSortLabel
                                        active={orderBy === 'city'}
                                        direction={orderBy === 'city' ? order : 'asc'}
                                        onClick={() => handleSortRequest('city')}
                                        style={{ color: '#12c099' }}
                                    >
                                        Location
                                    </TableSortLabel>
                                </CompactTableCell>
                                <CompactTableCell>
                                    <TableSortLabel
                                        active={orderBy === 'rating'}
                                        direction={orderBy === 'rating' ? order : 'asc'}
                                        onClick={() => handleSortRequest('rating')}
                                        style={{ color: '#12c099' }}
                                    >
                                        Rating
                                    </TableSortLabel>
                                </CompactTableCell>
                                <CompactTableCell>Actions</CompactTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {paginatedCoaches.map((coach) => (
                                <CompactTableRow key={coach.firebaseUid}>
                                    <CompactTableCell>
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <Avatar
                                                sx={{
                                                    marginRight: '10px',
                                                    width: 32,
                                                    height: 32,
                                                    fontSize: '1rem',
                                                }}
                                                src={coach.avatarImageUrl}
                                            >
                                                {coach.name.charAt(0)}
                                            </Avatar>
                                            <Link
                                                to={`/coach/${coach.firebaseUid}`}
                                                style={{
                                                    color: '#12c099',
                                                    textDecoration: 'none',
                                                    fontSize: '1rem',
                                                }}
                                            >
                                                {coach.name}
                                            </Link>
                                        </Box>
                                    </CompactTableCell>
                                    <CompactTableCell>{`${coach.city}, ${coach.state}`}</CompactTableCell>
                                    <CompactTableCell>{coach.rating}</CompactTableCell>
                                    <CompactTableCell>
                                        <IconButton
                                            onClick={() => openDeleteDialogHandler(coach.firebaseUid)}
                                            sx={{ color: '#12c099' }}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </CompactTableCell>
                                </CompactTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </StyledTableContainer>
            )}
            <PaginationBox>
                <Pagination
                    count={Math.ceil(filteredCoaches.length / rowsPerPage)}
                    page={page}
                    onChange={(event, value) => setPage(value)}
                    sx={{ button: { color: '#12c099' }, ul: { color: '#12c099' } }}
                />
            </PaginationBox>
            <Footer>
                <Typography variant="body2">
                    &copy; {new Date().getFullYear()} Mars Reel Media Corp. All rights reserved.
                </Typography>
            </Footer>

            {/* Delete Confirmation Dialog */}
            <Dialog
                open={openDeleteDialog}
                onClose={closeDeleteDialog}
                aria-labelledby="delete-dialog-title"
            >
                <DialogTitle id="delete-dialog-title">
                    Are you sure you want to delete this coach?
                </DialogTitle>
                <DialogActions>
                    <Button onClick={closeDeleteDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={confirmDelete} color="secondary">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </CoachesContainer>
    );
};

export default Coaches;
