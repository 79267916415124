import React, { useState } from 'react';
import { Modal, Box, Typography, Grid, Paper, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer
} from 'recharts';

const CustomPaper = styled(Paper)({
    padding: '1rem',
    backgroundColor: '#202020',
    color: '#fff',
    textAlign: 'center',
    borderRadius: '8px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
});

const dummyProfitLossData = [
    { name: 'Jan', revenue: 4000, cost: 2400, profit: 1600 },
    { name: 'Feb', revenue: 3000, cost: 1398, profit: 1602 },
    { name: 'Mar', revenue: 2000, cost: 980, profit: 1020 },
    { name: 'Apr', revenue: 2780, cost: 3908, profit: -1128 },
    { name: 'May', revenue: 1890, cost: 4800, profit: -2910 },
    { name: 'Jun', revenue: 2390, cost: 3800, profit: -1410 },
    { name: 'Jul', revenue: 3490, cost: 4300, profit: -810 },
];

const dummyPnLData = {
    Jan: { revenue: 4000, cost: 2400, profit: 1600 },
    Feb: { revenue: 3000, cost: 1398, profit: 1602 },
    Mar: { revenue: 2000, cost: 980, profit: 1020 },
    Apr: { revenue: 2780, cost: 3908, profit: -1128 },
    May: { revenue: 1890, cost: 4800, profit: -2910 },
    Jun: { revenue: 2390, cost: 3800, profit: -1410 },
    Jul: { revenue: 3490, cost: 4300, profit: -810 },
};

const ProfitLossModal = ({ open, onClose, location }) => {
    const [view, setView] = useState('graph'); // State to toggle between 'graph' and 'table'
    const [selectedMonth, setSelectedMonth] = useState(null); // State to manage selected month for traditional P&L

    const title = location.neighborhood
        ? `${location.city} - ${location.neighborhood} Profit/Loss`
        : `${location.city} Profit/Loss`;

    const handleViewChange = (newView) => {
        setView(newView);
        setSelectedMonth(null); // Reset selected month when changing views
    };

    const renderTableView = () => {
        const pnlData = selectedMonth ? dummyPnLData[selectedMonth] : null;

        return (
            <>
                <Typography variant="h6" gutterBottom>
                    {selectedMonth ? `${selectedMonth} Profit & Loss Statement` : 'Select a Month to View P&L'}
                </Typography>
                {selectedMonth && (
                    <TableContainer component={CustomPaper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ color: '#fff' }}>Description</TableCell>
                                    <TableCell sx={{ color: '#fff' }} align="right">Amount</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell sx={{ color: '#fff' }}>Revenue</TableCell>
                                    <TableCell sx={{ color: '#fff' }} align="right">${pnlData.revenue}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ color: '#fff' }}>Cost</TableCell>
                                    <TableCell sx={{ color: '#fff' }} align="right">${pnlData.cost}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ color: '#fff' }}>Profit</TableCell>
                                    <TableCell sx={{ color: '#fff' }} align="right">${pnlData.profit}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
                <Box sx={{ mt: 2 }}>
                    {Object.keys(dummyPnLData).map((month) => (
                        <Button
                            key={month}
                            variant="outlined"
                            sx={{ color: '#12c099', borderColor: '#12c099', mr: 1, mb: 1 }}
                            onClick={() => setSelectedMonth(month)}
                        >
                            {month}
                        </Button>
                    ))}
                </Box>
            </>
        );
    };

    const renderGraphView = () => (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <CustomPaper>
                    <Typography variant="h6">Profit/Loss Over Time</Typography>
                    <ResponsiveContainer width="100%" height={400}>
                        <BarChart data={dummyProfitLossData}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="revenue" fill="#8884d8" />
                            <Bar dataKey="cost" fill="#82ca9d" />
                            <Bar dataKey="profit" fill="#ffc658" />
                        </BarChart>
                    </ResponsiveContainer>
                </CustomPaper>
            </Grid>
        </Grid>
    );

    return (
        <Modal
            open={open}
            onClose={onClose}
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Box sx={{
                width: '80%',
                maxHeight: '80%',
                backgroundColor: '#1c1c1c',
                padding: '2rem',
                borderRadius: '8px',
                overflowY: 'auto',
            }}>
                <Typography variant="h4" gutterBottom>{title}</Typography>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                    <Button
                        variant={view === 'graph' ? 'contained' : 'outlined'}
                        onClick={() => handleViewChange('graph')}
                        sx={{ color: view === 'graph' ? '#fff' : '#12c099', backgroundColor: view === 'graph' ? '#12c099' : 'transparent' }}
                    >
                        Chart View
                    </Button>
                    <Button
                        variant={view === 'table' ? 'contained' : 'outlined'}
                        onClick={() => handleViewChange('table')}
                        sx={{ color: view === 'table' ? '#fff' : '#12c099', backgroundColor: view === 'table' ? '#12c099' : 'transparent' }}
                    >
                        P&L View
                    </Button>
                </Box>

                {view === 'graph' ? renderGraphView() : renderTableView()}

                <Box sx={{ mt: 2, textAlign: 'right' }}>
                    <Button variant="contained" color="primary">
                        Export as PDF
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default ProfitLossModal;
