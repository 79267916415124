import React, { useEffect, useState, useRef } from 'react';
import {
    Avatar,
    TextField,
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Paper,
    Pagination,
    Typography,
    IconButton,
    Button,
    Collapse,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { LoadScript, StandaloneSearchBox } from '@react-google-maps/api';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';

const SearchBar = styled(TextField)({
    marginBottom: '20px',
    width: '100%',
    backgroundColor: '#202020',
    borderRadius: '8px',
    color: '#fff',
    '& .MuiInputBase-root': {
        color: '#fff',
        fontSize: '1rem',
    },
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#12c099',
    },
    '& .MuiInputLabel-root': {
        color: '#12c099',
        fontSize: '1rem',
    },
});

const StyledTableContainer = styled(TableContainer)({
    backgroundColor: '#202020',
    borderRadius: '12px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
    overflowX: 'auto',
});

const CompactTableCell = styled(TableCell)({
    padding: '16px 20px',
    fontSize: '1rem',
    color: '#fff',
});

const PaginationBox = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
});

const EmployeesContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    padding: '1rem',
    backgroundColor: '#1c1c1c',
    minHeight: '100vh',
});

const HeaderContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
});

const Title = styled(Typography)({
    fontSize: '1.8rem',
    color: '#fff',
    fontWeight: 'bold',
});

const AddEmployeeForm = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    padding: '1rem',
    backgroundColor: '#282828',
    borderRadius: '8px',
    color: '#fff',
});

const Employees = () => {
    const [employees, setEmployees] = useState([]);
    const [newEmployee, setNewEmployee] = useState({
        name: '',
        title: '',
        city: '',
        state: '',
        country: '',
        email: '',
        phoneNumber: '',
    });
    const [expanded, setExpanded] = useState(false); // State to control form visibility
    const [searchTerm, setSearchTerm] = useState('');
    const [page, setPage] = useState(1);
    const rowsPerPage = 5;
    const searchBoxRef = useRef(null); // Google Maps SearchBox reference

    // Fetch Employees from Backend
    useEffect(() => {
        const fetchEmployees = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/employees`);
                setEmployees(response.data);
            } catch (error) {
                console.error('Error fetching employees:', error);
            }
        };
        fetchEmployees();
    }, []);

    // Handle input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewEmployee((prev) => ({ ...prev, [name]: value }));
    };

    // Handle Google Places Autocomplete
    const handlePlacesChanged = () => {
        const places = searchBoxRef.current.getPlaces();
        if (places.length > 0) {
            const place = places[0];
            const addressComponents = place.address_components;

            const city = addressComponents.find((comp) => comp.types.includes('locality'))?.long_name;
            const state = addressComponents.find((comp) => comp.types.includes('administrative_area_level_1'))?.short_name;
            const country = addressComponents.find((comp) => comp.types.includes('country'))?.long_name;

            setNewEmployee((prev) => ({
                ...prev,
                city: city || '',
                state: state || '',
                country: country || '',
            }));
        }
    };

    // Add a new employee
    const handleAddEmployee = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/create-employee`, newEmployee);
            setEmployees([...employees, response.data]);
            setNewEmployee({
                name: '',
                title: '',
                city: '',
                state: '',
                country: '',
                email: '',
                phoneNumber: '',
            });
            setExpanded(false);
        } catch (error) {
            console.error('Error adding employee:', error);
        }
    };

    return (
        <EmployeesContainer>
            <HeaderContainer>
                <Title>Employees</Title>
                <IconButton onClick={() => setExpanded(!expanded)} sx={{ color: '#12c099' }}>
                    <AddCircleIcon />
                    <Typography variant="body1" sx={{ marginLeft: '8px', color: '#12c099' }}>
                        Add New Employee
                    </Typography>
                    {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
            </HeaderContainer>

            <SearchBar
                variant="outlined"
                label="Search Employees"
                onChange={(e) => setSearchTerm(e.target.value)}
            />

            <Collapse in={expanded}>
                <AddEmployeeForm>
                    <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY} libraries={['places']}>
                        <StandaloneSearchBox onLoad={ref => (searchBoxRef.current = ref)} onPlacesChanged={handlePlacesChanged}>
                            <TextField
                                variant="outlined"
                                placeholder="Enter city, state, or country"
                                sx={{ backgroundColor: '#202020', color: '#fff', width: '100%' }}
                                inputProps={{ style: { color: '#fff' } }}
                            />
                        </StandaloneSearchBox>
                    </LoadScript>

                    <TextField
                        label="Name"
                        name="name"
                        value={newEmployee.name}
                        onChange={handleInputChange}
                        variant="outlined"
                        sx={{ backgroundColor: '#202020', color: '#fff', width: '100%' }}
                        InputLabelProps={{ style: { color: '#12c099' } }}
                    />

                    <TextField
                        label="Email"
                        name="email"
                        value={newEmployee.email}
                        onChange={handleInputChange}
                        variant="outlined"
                        sx={{ backgroundColor: '#202020', color: '#fff', width: '100%' }}
                        InputLabelProps={{ style: { color: '#12c099' } }}
                    />

                    <TextField
                        label="Phone Number"
                        name="phoneNumber"
                        value={newEmployee.phoneNumber}
                        onChange={handleInputChange}
                        variant="outlined"
                        sx={{ backgroundColor: '#202020', color: '#fff', width: '100%' }}
                        InputLabelProps={{ style: { color: '#12c099' } }}
                    />

                    {/* Title Dropdown */}
                    <TextField
                        label="Title"
                        name="title"
                        value={newEmployee.title}
                        onChange={handleInputChange}
                        variant="outlined"
                        select
                        SelectProps={{
                            native: true,
                        }}
                        sx={{ backgroundColor: '#202020', color: '#fff', width: '100%' }}
                        InputLabelProps={{ style: { color: '#12c099' } }}
                    >
                        <option value="">Select Title</option>
                        <option value="City Manager I">City Manager I</option>
                        <option value="City Manager II">City Manager II</option>
                        <option value="City Manager III">City Manager III</option>
                        <option value="State Manager I">State Manager I</option>
                        <option value="State Manager II">State Manager II</option>
                        <option value="State Manager III">State Manager III</option>
                        <option value="Country Manager I">Country Manager I</option>
                        <option value="Country Manager II">Country Manager II</option>
                        <option value="Country Manager III">Country Manager III</option>
                    </TextField>

                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: '#12c099',
                            color: '#000',
                            marginTop: '10px',
                        }}
                        onClick={handleAddEmployee}
                    >
                        Add Employee
                    </Button>
                </AddEmployeeForm>
            </Collapse>

            {/* Display Employee Table */}
            <StyledTableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <CompactTableCell>Name</CompactTableCell>
                            <CompactTableCell>Title</CompactTableCell>
                            <CompactTableCell>City</CompactTableCell>
                            <CompactTableCell>State</CompactTableCell>
                            <CompactTableCell>Country</CompactTableCell>
                            <CompactTableCell>Email</CompactTableCell>
                            <CompactTableCell>Actions</CompactTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {employees.slice((page - 1) * rowsPerPage, page * rowsPerPage).map((employee) => (
                            <TableRow key={employee.firebaseUid}>
                                <CompactTableCell>{employee.name}</CompactTableCell>
                                <CompactTableCell>{employee.title}</CompactTableCell>
                                <CompactTableCell>{employee.location.city.join(', ')}</CompactTableCell>
                                <CompactTableCell>{employee.location.state.join(', ')}</CompactTableCell>
                                <CompactTableCell>{employee.location.country}</CompactTableCell>
                                <CompactTableCell>{employee.email}</CompactTableCell>
                                <CompactTableCell>
                                    <IconButton onClick={() => console.log('Delete', employee.firebaseUid)}>
                                        <DeleteIcon sx={{ color: '#ff1744' }} />
                                    </IconButton>
                                </CompactTableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </StyledTableContainer>

            <PaginationBox>
                <Pagination
                    count={Math.ceil(employees.length / rowsPerPage)}
                    page={page}
                    onChange={(event, value) => setPage(value)}
                    sx={{ button: { color: '#12c099' }, ul: { color: '#12c099' } }}
                />
            </PaginationBox>
        </EmployeesContainer>
    );
};

export default Employees;
