import React, { useState, useEffect } from 'react';
import { Box, Typography, Avatar, Grid, Paper, List, ListItem, ListItemText, TextField, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const OverviewContainer = styled(Box)({
    padding: '2rem',
    backgroundColor: '#1c1c1c',
    color: '#fff',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
});

const SectionTitle = styled(Typography)({
    color: '#12c099',
    fontWeight: 'bold',
    marginBottom: '1rem',
});

const Athlete = () => {
    const { firebaseUid } = useParams(); // Get firebaseUid from the URL
    const [athlete, setAthlete] = useState(null);
    const [loading, setLoading] = useState(true);
    const [creditAmount, setCreditAmount] = useState('');

    useEffect(() => {
        const fetchAthlete = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/athlete/${firebaseUid}`);
                setAthlete(response.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching athlete data:', error);
                setLoading(false);
            }
        };
        fetchAthlete();
    }, [firebaseUid]);

    const handleAddCredits = async () => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/update-user-balance/${firebaseUid}`, {
                creditsToAdd: parseFloat(creditAmount)
            });
            // Update the account balance in the state
            setAthlete({ ...athlete, accountBalance: response.data.accountBalance });
            setCreditAmount('');
        } catch (error) {
            console.error('Error adding credits:', error);
        }
    };

    if (loading) {
        return <Typography variant="h6" color="inherit">Loading...</Typography>;
    }

    if (!athlete) {
        return <Typography variant="h6" color="inherit">Athlete not found.</Typography>;
    }

    return (
        <OverviewContainer>
            <Typography variant="h4" gutterBottom>Athlete Overview</Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={4} md={3}>
                    <Avatar src={athlete.avatar} sx={{ width: 120, height: 120 }} />
                </Grid>
                <Grid item xs={12} sm={8} md={9}>
                    <Typography variant="h5">{`${athlete.firstName} ${athlete.lastName}`}</Typography>
                    <Typography variant="body1">Username: {athlete.username}</Typography>
                    <Typography variant="body1">Email: {athlete.email}</Typography>
                    <Typography variant="body1">Phone: {athlete.phone}</Typography>
                    <Typography variant="body1">Account Balance: ${athlete.accountBalance}</Typography>
                    <Typography variant="body1">Bio: {athlete.bio}</Typography>
                </Grid>
            </Grid>

            <Paper sx={{ padding: '1.5rem', backgroundColor: '#202020', color: '#fff', borderRadius: '8px', marginTop: '2rem' }}>
                <SectionTitle variant="h6">Add Credits to Account</SectionTitle>
                <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center', flexWrap: 'wrap' }}>
                    <TextField
                        variant="outlined"
                        label="Credit Amount"
                        type="number"
                        value={creditAmount}
                        onChange={(e) => setCreditAmount(e.target.value)}
                        sx={{ backgroundColor: '#282828', color: '#fff', width: '200px' }}
                        InputLabelProps={{
                            style: { color: '#12c099' },
                        }}
                        InputProps={{
                            style: { color: '#fff' },
                        }}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleAddCredits}
                        sx={{ backgroundColor: '#12c099', color: '#fff' }}
                    >
                        Add Credits
                    </Button>
                </Box>
            </Paper>

            <Paper sx={{ padding: '1.5rem', backgroundColor: '#202020', color: '#fff', borderRadius: '8px', marginTop: '2rem' }}>
                <SectionTitle variant="h6">Plan Details</SectionTitle>
                <List>
                    {athlete.planDetails.map((plan, index) => (
                        <ListItem key={index}>
                            <ListItemText
                                primary={plan.type === 'subscription' ? 'Subscription' : 'Pay As You Go'}
                                secondary={`Price Per Credit: $${plan.pricePerCredit} | Monthly Fee: $${plan.monthlyFee || 'N/A'} | Credits Per Month: ${plan.creditsPerMonth || 'N/A'}`}
                            />
                        </ListItem>
                    ))}
                </List>
            </Paper>
        </OverviewContainer>
    );
};

export default Athlete;
