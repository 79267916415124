import React, { useEffect, useState } from 'react';
import {
    Avatar,
    TextField,
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Paper,
    Pagination,
    Typography,
    IconButton,
    Dialog,
    DialogTitle,
    DialogActions,
    Button,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import axios from 'axios';
import DeleteIcon from '@mui/icons-material/Delete';

const SearchBar = styled(TextField)({
    marginBottom: '20px',
    width: '100%',
    backgroundColor: '#202020',
    borderRadius: '8px',
    color: '#fff',
    '& .MuiInputBase-root': {
        color: '#fff',
        fontSize: '1rem',
    },
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#12c099',
    },
    '& .MuiInputLabel-root': {
        color: '#12c099',
        fontSize: '1rem',
    },
});

const StyledTableContainer = styled(TableContainer)({
    backgroundColor: '#202020',
    borderRadius: '12px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
    overflowX: 'auto',
    '@media (max-width: 768px)': {
        width: '100%',
    },
});

const CompactTableCell = styled(TableCell)({
    padding: '16px 20px',
    fontSize: '1rem',
    '@media (max-width: 768px)': {
        display: 'block',
        width: '100%',
        padding: '12px 0',
        fontSize: '0.9rem',
        textAlign: 'left',
    },
    color: '#fff',
});

const CompactTableRow = styled(TableRow)({
    '@media (max-width: 768px)': {
        display: 'block',
        width: '100%',
        marginBottom: '20px',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
        borderRadius: '8px',
        backgroundColor: '#202020',
        overflow: 'hidden',
    },
});

const PaginationBox = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
    '@media (max-width: 768px)': {
        marginTop: '10px',
    },
});

const AthletesContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    padding: '1rem',
    backgroundColor: '#1c1c1c',
    minHeight: '100vh',
    '@media (max-width: 768px)': {
        padding: '0.5rem',
    },
});

const HeaderContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
    flexWrap: 'wrap',
    '@media (max-width: 768px)': {
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
});

const Title = styled(Typography)({
    fontSize: '1.8rem',
    color: '#fff',
    fontWeight: 'bold',
    '@media (max-width: 768px)': {
        fontSize: '1.5rem',
    },
});

const Footer = styled(Box)({
    padding: '1rem',
    textAlign: 'center',
    color: '#fff',
    backgroundColor: 'transparent',
    marginTop: 'auto',
});

const Athletes = () => {
    const [athletes, setAthletes] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [page, setPage] = useState(1);
    const rowsPerPage = 5;
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('firstName');
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [selectedFirebaseUid, setSelectedFirebaseUid] = useState(null);

    useEffect(() => {
        // Fetch the athletes data from the server
        const fetchAthletes = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/users`);
                setAthletes(response.data);
            } catch (error) {
                console.error('Error fetching athletes data:', error);
            }
        };
        fetchAthletes();
    }, []);

    const handleSortRequest = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const openDeleteDialogHandler = (firebaseUid) => {
        setSelectedFirebaseUid(firebaseUid);
        setOpenDeleteDialog(true);
    };

    const closeDeleteDialog = () => {
        setOpenDeleteDialog(false);
        setSelectedFirebaseUid(null);
    };

    const confirmDelete = async () => {
        try {
            await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/delete/${selectedFirebaseUid}`);
            setAthletes(athletes.filter((athlete) => athlete.firebaseUid !== selectedFirebaseUid));
            closeDeleteDialog();
        } catch (error) {
            console.error('Error deleting athlete:', error);
        }
    };

    const filteredAthletes = athletes.filter((athlete) =>
        `${athlete.firstName} ${athlete.lastName}`.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const sortedAthletes = filteredAthletes.sort((a, b) => {
        if (order === 'asc') {
            return a[orderBy]?.localeCompare(b[orderBy]) || 0;
        }
        return b[orderBy]?.localeCompare(a[orderBy]) || 0;
    });

    const paginatedAthletes = sortedAthletes.slice((page - 1) * rowsPerPage, page * rowsPerPage);

    return (
        <AthletesContainer>
            <HeaderContainer>
                <Title variant="h4" gutterBottom>Athletes</Title>
                <SearchBar
                    variant="outlined"
                    label="Search Athletes"
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
            </HeaderContainer>
            <StyledTableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <CompactTableCell>
                                <TableSortLabel
                                    active={orderBy === 'firstName'}
                                    direction={orderBy === 'firstName' ? order : 'asc'}
                                    onClick={() => handleSortRequest('firstName')}
                                    style={{ color: '#12c099' }}
                                >
                                    Name
                                </TableSortLabel>
                            </CompactTableCell>
                            <CompactTableCell>
                                <TableSortLabel
                                    active={orderBy === 'planDetails'}
                                    direction={orderBy === 'planDetails' ? order : 'asc'}
                                    onClick={() => handleSortRequest('planDetails')}
                                    style={{ color: '#12c099' }}
                                >
                                    Membership Type
                                </TableSortLabel>
                            </CompactTableCell>
                            <CompactTableCell>
                                <TableSortLabel
                                    active={orderBy === 'dateJoined'}
                                    direction={orderBy === 'dateJoined' ? order : 'asc'}
                                    onClick={() => handleSortRequest('dateJoined')}
                                    style={{ color: '#12c099' }}
                                >
                                    Date Joined
                                </TableSortLabel>
                            </CompactTableCell>
                            <CompactTableCell>
                                <TableSortLabel
                                    active={orderBy === 'gender'}
                                    direction={orderBy === 'gender' ? order : 'asc'}
                                    onClick={() => handleSortRequest('gender')}
                                    style={{ color: '#12c099' }}
                                >
                                    Gender
                                </TableSortLabel>
                            </CompactTableCell>
                            <CompactTableCell>
                                <TableSortLabel
                                    active={orderBy === 'address'}
                                    direction={orderBy === 'address' ? order : 'asc'}
                                    onClick={() => handleSortRequest('address')}
                                    style={{ color: '#12c099' }}
                                >
                                    Location
                                </TableSortLabel>
                            </CompactTableCell>
                            <CompactTableCell>
                                <TableSortLabel
                                    active={orderBy === 'country'}
                                    direction={orderBy === 'country' ? order : 'asc'}
                                    onClick={() => handleSortRequest('country')}
                                    style={{ color: '#12c099' }}
                                >
                                    Country
                                </TableSortLabel>
                            </CompactTableCell>
                            <CompactTableCell>
                                Actions
                            </CompactTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {paginatedAthletes.map((athlete) => (
                            <CompactTableRow key={athlete._id}>
                                <CompactTableCell>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Avatar
                                            sx={{ marginRight: '10px', width: 32, height: 32, fontSize: '1rem' }}
                                            src={athlete.avatar}
                                        >
                                            {athlete.firstName?.charAt(0)}
                                        </Avatar>
                                        <Link to={`/athlete/${athlete.firebaseUid}`} style={{ color: '#12c099', textDecoration: 'none', fontSize: '1rem' }}>
                                            {`${athlete.firstName} ${athlete.lastName}`}
                                        </Link>
                                    </Box>
                                </CompactTableCell>
                                <CompactTableCell>
                                    {athlete.planDetails?.[0]?.isRecurring ? 'Recurring' : 'PayGo'}
                                </CompactTableCell>
                                <CompactTableCell>{new Date(athlete.dateJoined).toLocaleDateString()}</CompactTableCell>
                                <CompactTableCell>{athlete.gender}</CompactTableCell>
                                <CompactTableCell>{`${athlete.city}, ${athlete.state}`}</CompactTableCell>
                                <CompactTableCell>{athlete.country || ''}</CompactTableCell>
                                <CompactTableCell>
                                    <IconButton
                                        onClick={() => openDeleteDialogHandler(athlete.firebaseUid)}
                                        sx={{ color: '#ff1744' }}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </CompactTableCell>
                            </CompactTableRow>
                        ))}
                    </TableBody>
                </Table>
            </StyledTableContainer>
            <PaginationBox>
                <Pagination
                    count={Math.ceil(filteredAthletes.length / rowsPerPage)}
                    page={page}
                    onChange={(event, value) => setPage(value)}
                    sx={{ button: { color: '#12c099' }, ul: { color: '#12c099' } }}
                />
            </PaginationBox>
            <Dialog
                open={openDeleteDialog}
                onClose={closeDeleteDialog}
                aria-labelledby="delete-dialog-title"
            >
                <DialogTitle id="delete-dialog-title">
                    Are you sure you want to delete this athlete?
                </DialogTitle>
                <DialogActions>
                    <Button onClick={closeDeleteDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={confirmDelete} color="secondary">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
            <Footer>
                <Typography variant="body2">
                    &copy; {new Date().getFullYear()} Mars Reel Media Corp. All rights reserved.
                </Typography>
            </Footer>
        </AthletesContainer>
    );
};

export default Athletes;
