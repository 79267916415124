import React from 'react';
import { Box, Typography } from '@mui/material';

const FooterComponent = () => {
    return (
        <Box sx={{ padding: '1rem', textAlign: 'center', color: '#fff', backgroundColor: 'transparent', marginTop: 'auto' }}>
            <Typography variant="body2">
                &copy; {new Date().getFullYear()} Mars Reel Media Corp. All rights reserved.
            </Typography>
        </Box>
    );
};

export default FooterComponent;
