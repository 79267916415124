import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, Grid, Card, CardMedia, Paper, List, ListItem, ListItemText, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import PaymentManagement from '../components/PaymentManagement';

const PageContainer = styled(Box)({
    padding: '2rem',
    backgroundColor: '#1c1c1c',
    color: '#fff',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
});

const SectionTitle = styled(Typography)({
    marginBottom: '1rem',
    color: '#12c099',
    fontWeight: 'bold',
});

const StyledPaper = styled(Paper)({
    backgroundColor: '#282828',
    padding: '1rem',
    marginBottom: '2rem',
});

const StyledTableContainer = styled(TableContainer)({
    backgroundColor: '#282828',
    color: '#fff',
});

const Footer = styled(Box)({
    padding: '1rem',
    color: '#fff',
    textAlign: 'center',
    marginTop: '2rem',
});

const fetchUserDetails = async (firebaseUid) => {
    try {
        const [athleteRes, coachRes, siteManagerRes] = await Promise.all([
            axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/athlete/${firebaseUid}`), // Athlete endpoint
            axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/coach/${firebaseUid}`), // Coach endpoint
            axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/site-manager/${firebaseUid}`), // Site Manager endpoint
        ]);

        if (athleteRes.data) {
            return { type: 'athlete', name: athleteRes.data.name, firebaseUid };
        } else if (coachRes.data) {
            return { type: 'coach', name: coachRes.data.name, firebaseUid };
        } else if (siteManagerRes.data) {
            return { type: 'siteManager', name: siteManagerRes.data.name, firebaseUid };
        }
    } catch (error) {
        console.error('Error fetching user details:', error);
        return { type: 'unknown', name: firebaseUid };
    }
};

const Session = () => {
    const { id } = useParams(); // Get the session ID from the URL
    const [session, setSession] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchSession = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/session/${id}`);
                const sessionData = response.data;

                // Fetch details for reservedBy users
                const detailedReservations = await Promise.all(
                    sessionData.reservedBy.map(async (firebaseUid) => {
                        const userDetails = await fetchUserDetails(firebaseUid);
                        return userDetails;
                    })
                );

                // Fetch details for attendees
                const detailedAttendees = await Promise.all(
                    sessionData.attendees.map(async (firebaseUid) => {
                        const userDetails = await fetchUserDetails(firebaseUid);
                        return userDetails;
                    })
                );

                setSession({
                    ...sessionData,
                    reservedBy: detailedReservations,
                    attendees: detailedAttendees,
                });
                setLoading(false);
            } catch (error) {
                console.error('Error fetching session details:', error);
                setLoading(false);
            }
        };
        fetchSession();
    }, [id]);

    if (loading) {
        return <Typography variant="h6" color="primary">Loading...</Typography>;
    }

    if (!session) {
        return <Typography variant="h6" color="error">Session not found.</Typography>;
    }

    return (
        <PageContainer>
            <Box>
                <Typography variant="h4" gutterBottom>{session.name}</Typography>
                <Typography variant="body1" gutterBottom>{session.description}</Typography>
                <Typography variant="body2" gutterBottom>Event Date: {new Date(session.eventDate).toLocaleDateString()}</Typography>

                {/* Images Section */}
                <SectionTitle variant="h5">Venue Images</SectionTitle>
                <Grid container spacing={2}>
                    {[session.venueImage1, session.venueImage2, session.venueImage3].map((image, index) => image && (
                        <Grid item xs={12} sm={4} key={index}>
                            <Card sx={{ backgroundColor: '#282828' }}>
                                <CardMedia
                                    component="img"
                                    height="140"
                                    image={image}
                                    alt={`Venue ${index + 1}`}
                                />
                            </Card>
                        </Grid>
                    ))}
                </Grid>

                {/* Payment Management */}
                <SectionTitle variant="h5">Payment Management</SectionTitle>
                <PaymentManagement
                    coaches={session.coachNames.map((name, i) => ({
                        name,
                        rate: session.coachRate,
                        owed: session.coachRate * (session.coachRatings[i] || 2),
                    }))}
                    siteManagers={session.siteManagerNames.map((name, i) => ({
                        name,
                        rate: session.siteManagerRate,
                        owed: session.siteManagerRate * (session.siteManagerAvatars[i] || 2),
                    }))}
                />

                {/* Reservations Section */}
                <SectionTitle variant="h5">Reservations</SectionTitle>
                <StyledPaper>
                    <List>
                        {session.reservedBy.map((reservation, index) => (
                            <ListItem key={index} sx={{ borderBottom: '1px solid #404040' }}>
                                <ListItemText
                                    primary={
                                        reservation.type === 'athlete' ? (
                                            <a href={`https://admin.marsreel.com/athlete/${reservation.firebaseUid}`} style={{ color: '#12c099' }}>
                                                {reservation.name}
                                            </a>
                                        ) : reservation.type === 'coach' ? (
                                            <a href={`https://admin.marsreel.com/coach/${reservation.firebaseUid}`} style={{ color: '#12c099' }}>
                                                {reservation.name}
                                            </a>
                                        ) : reservation.type === 'siteManager' ? (
                                            <a href={`https://admin.marsreel.com/site-manager/${reservation.firebaseUid}`} style={{ color: '#12c099' }}>
                                                {reservation.name}
                                            </a>
                                        ) : (
                                            reservation.name
                                        )
                                    }
                                    sx={{ color: '#fff' }}
                                />
                            </ListItem>
                        ))}
                    </List>
                </StyledPaper>

                {/* Attendees Section */}
                <SectionTitle variant="h5">Attendees</SectionTitle>
                <StyledPaper>
                    <List>
                        {session.attendees.map((attendee, index) => (
                            <ListItem key={index} sx={{ borderBottom: '1px solid #404040' }}>
                                <ListItemText
                                    primary={
                                        attendee.type === 'athlete' ? (
                                            <a href={`https://admin.marsreel.com/athlete/${attendee.firebaseUid}`} style={{ color: '#12c099' }}>
                                                {attendee.name}
                                            </a>
                                        ) : attendee.type === 'coach' ? (
                                            <a href={`https://admin.marsreel.com/coach/${attendee.firebaseUid}`} style={{ color: '#12c099' }}>
                                                {attendee.name}
                                            </a>
                                        ) : attendee.type === 'siteManager' ? (
                                            <a href={`https://admin.marsreel.com/site-manager/${attendee.firebaseUid}`} style={{ color: '#12c099' }}>
                                                {attendee.name}
                                            </a>
                                        ) : (
                                            attendee.name
                                        )
                                    }
                                    sx={{ color: '#fff' }}
                                />
                            </ListItem>
                        ))}
                    </List>
                </StyledPaper>

                {/* Profit/Loss Section */}
                <SectionTitle variant="h5">Profit/Loss</SectionTitle>
                <StyledTableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ color: '#12c099', fontWeight: 'bold' }}>Category</TableCell>
                                <TableCell sx={{ color: '#12c099', fontWeight: 'bold' }}>Amount ($)</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {session.profitLossData?.map((entry, index) => (
                                <TableRow key={index}>
                                    <TableCell sx={{ color: '#fff' }}>{entry.category}</TableCell>
                                    <TableCell sx={{ color: '#fff' }}>{entry.amount.toLocaleString()}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </StyledTableContainer>
            </Box>

            <Footer>
                <Typography variant="body2">
                    &copy; {new Date().getFullYear()} Mars Reel Media Corp. All rights reserved.
                </Typography>
            </Footer>
        </PageContainer>
    );
};

export default Session;
