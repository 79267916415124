// src/firebase.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyB6p0ytdfkpa0JOTb0XOgon5xoYEFPWz1c",
    authDomain: "marsreelacademy.firebaseapp.com",
    projectId: "marsreelacademy",
    storageBucket: "marsreelacademy.appspot.com",
    messagingSenderId: "270854752661",
    appId: "1:270854752661:web:136be2d1db0a64c85f0e9c",
    measurementId: "G-DVWKVVPFSZ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth };
