import React, { useState, useRef } from 'react';
import {
    TextField,
    Box,
    Grid,
    Typography,
    Button,
    IconButton,
    Collapse,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Pagination,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { LoadScript, StandaloneSearchBox } from '@react-google-maps/api';
import { useNavigate } from 'react-router-dom';

const initialMarkets = [
    { id: 1, country: 'United States', state: 'California', city: 'Los Angeles', teamLeader: 'John Doe', coverage: 80, revenue: 150000, margin: 20 },
    { id: 2, country: 'United States', state: 'California', city: 'San Francisco', teamLeader: 'Jane Smith', coverage: 75, revenue: 120000, margin: 18 },
    { id: 3, country: 'United States', state: 'New York', city: 'New York City', teamLeader: 'Alice Johnson', coverage: 85, revenue: 200000, margin: 22 },
    { id: 4, country: 'United States', state: 'Florida', city: 'Miami', teamLeader: 'Bob Brown', coverage: 65, revenue: 90000, margin: 15 },
    { id: 5, country: 'United States', state: 'Texas', city: 'Austin', teamLeader: 'Chris Lee', coverage: 90, revenue: 130000, margin: 25 },
    { id: 6, country: 'Canada', state: 'Ontario', city: 'Toronto', teamLeader: 'Emily Davis', coverage: 88, revenue: 140000, margin: 19 },
    { id: 7, country: 'Canada', state: 'British Columbia', city: 'Vancouver', teamLeader: 'Michael Scott', coverage: 70, revenue: 110000, margin: 17 },
    { id: 8, country: 'United Kingdom', state: 'England', city: 'London', teamLeader: 'David Williams', coverage: 95, revenue: 220000, margin: 28 },
    { id: 9, country: 'Australia', state: 'New South Wales', city: 'Sydney', teamLeader: 'Olivia Taylor', coverage: 85, revenue: 180000, margin: 23 },
    { id: 10, country: 'Australia', state: 'Victoria', city: 'Melbourne', teamLeader: 'Liam Wilson', coverage: 82, revenue: 160000, margin: 21 },
];

const Container = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    padding: '2rem',
    backgroundColor: '#1c1c1c',
    minHeight: '100vh',
    color: '#fff',
});

const Header = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
});

const AddMarketForm = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    padding: '1rem',
    backgroundColor: '#282828',
    borderRadius: '8px',
    color: '#fff',
});

const StyledTableContainer = styled(TableContainer)({
    backgroundColor: '#202020',
    borderRadius: '12px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
});

const CompactTableCell = styled(TableCell)({
    padding: '16px 20px',
    fontSize: '1rem',
    '@media (max-width: 768px)': {
        display: 'block',
        width: '100%',
        padding: '12px 0',
        fontSize: '0.9rem',
        textAlign: 'left',
    },
    color: '#fff',
    cursor: 'pointer',
    '&:hover': {
        color: '#12c099',
    },
});

const CompactTableRow = styled(TableRow)({
    '@media (max-width: 768px)': {
        display: 'block',
        width: '100%',
        marginBottom: '20px',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
        borderRadius: '8px',
        backgroundColor: '#202020',
        overflow: 'hidden',
    },
});

const PaginationBox = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
    '@media (max-width: 768px)': {
        marginTop: '10px',
    },
});

const StyledTextField = styled(TextField)({
    '& .MuiInputBase-input': {
        color: '#12c099', // Green text color inside the input
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#12c099', // Default border color
        },
        '&:hover fieldset': {
            borderColor: '#12c099', // Border color on hover
        },
        '&.Mui-focused fieldset': {
            borderColor: '#12c099', // Border color when focused
        },
    },
    '& .MuiInputLabel-root': {
        color: '#12c099', // Green label color
    },
});

const Markets = () => {
    const [markets, setMarkets] = useState(initialMarkets);
    const [newMarket, setNewMarket] = useState({
        country: '',
        state: '',
        city: '',
        teamLeader: '',
        coverage: '',
        revenue: '',
        margin: '',
    });
    const [searchTerm, setSearchTerm] = useState('');
    const [page, setPage] = useState(1);
    const rowsPerPage = 5;
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('country');
    const [expanded, setExpanded] = useState(false);
    const searchBoxRef = useRef(null);
    const navigate = useNavigate();

    const handleSortRequest = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleCountryClick = (country) => {
        navigate(`/countryoverview/${encodeURIComponent(country)}`);
    };

    const handleStateClick = (state) => {
        navigate(`/stateoverview/${encodeURIComponent(state)}`);
    };

    const handleCityClick = (city) => {
        navigate(`/cityoverview/${encodeURIComponent(city)}`);
    };

    const handleAddMarket = () => {
        const newId = markets.length + 1;
        setMarkets([...markets, { id: newId, ...newMarket }]);
        setNewMarket({
            country: '',
            state: '',
            city: '',
            teamLeader: '',
            coverage: '',
            revenue: '',
            margin: '',
        });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewMarket((prev) => ({ ...prev, [name]: value }));
    };

    const handlePlacesChanged = () => {
        const places = searchBoxRef.current.getPlaces();
        if (places.length > 0) {
            const place = places[0];
            const addressComponents = place.address_components;

            const city = addressComponents.find((comp) =>
                comp.types.includes('locality')
            )?.long_name;

            const state = addressComponents.find((comp) =>
                comp.types.includes('administrative_area_level_1')
            )?.short_name;

            const country = addressComponents.find((comp) =>
                comp.types.includes('country')
            )?.long_name;

            setNewMarket((prev) => ({
                ...prev,
                city: city || '',
                state: state || '',
                country: country || '',
            }));
        }
    };

    const filteredMarkets = markets.filter(market =>
        market.country.toLowerCase().includes(searchTerm.toLowerCase()) ||
        market.state.toLowerCase().includes(searchTerm.toLowerCase()) ||
        market.city.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const sortedMarkets = filteredMarkets.sort((a, b) => {
        if (order === 'asc') {
            return a[orderBy].localeCompare(b[orderBy]);
        }
        return b[orderBy].localeCompare(a[orderBy]);
    });

    const paginatedMarkets = sortedMarkets.slice((page - 1) * rowsPerPage, page * rowsPerPage);

    return (
        <Container>
            <Header>
                <Typography variant="h4">Markets</Typography>
                <IconButton onClick={() => setExpanded(!expanded)} sx={{ color: '#12c099' }}>
                    <AddCircleIcon />
                    <Typography variant="body1" sx={{ marginLeft: '8px', color: '#12c099' }}>
                        Add New Market
                    </Typography>
                    {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
            </Header>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        placeholder="Search by city, state, or country"
                        fullWidth
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        sx={{
                            backgroundColor: '#202020',
                            color: '#fff',
                            marginBottom: '20px',
                            '& .MuiInputBase-input': {
                                color: '#12c099',
                            },
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: '#12c099',
                                },
                                '&:hover fieldset': {
                                    borderColor: '#12c099',
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: '#12c099',
                                },
                            },
                            '& .MuiInputLabel-root': {
                                color: '#12c099',
                            },
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Collapse in={expanded}>
                        <AddMarketForm>
                            <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY} libraries={['places']}>
                                <StandaloneSearchBox onLoad={ref => (searchBoxRef.current = ref)} onPlacesChanged={handlePlacesChanged}>
                                    <TextField
                                        variant="outlined"
                                        placeholder="Enter city, state, or country"
                                        sx={{ backgroundColor: '#202020', color: '#fff', width: '100%' }}
                                        inputProps={{ style: { color: '#fff' } }}
                                    />
                                </StandaloneSearchBox>
                            </LoadScript>
                            <StyledTextField
                                label="City"
                                name="city"
                                value={newMarket.city}
                                onChange={handleInputChange}
                                variant="outlined"
                            />
                            <StyledTextField
                                label="State"
                                name="state"
                                value={newMarket.state}
                                onChange={handleInputChange}
                                variant="outlined"
                            />
                            <StyledTextField
                                label="Country"
                                name="country"
                                value={newMarket.country}
                                onChange={handleInputChange}
                                variant="outlined"
                            />
                            <Button
                                variant="contained"
                                sx={{
                                    backgroundColor: '#12c099',
                                    color: '#000',
                                    marginTop: '10px',
                                }}
                                onClick={handleAddMarket}
                            >
                                Add Market
                            </Button>
                        </AddMarketForm>
                    </Collapse>
                </Grid>
                <Grid item xs={12}>
                    <StyledTableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <CompactTableCell>
                                        <TableSortLabel
                                            active={orderBy === 'country'}
                                            direction={orderBy === 'country' ? order : 'asc'}
                                            onClick={() => handleSortRequest('country')}
                                            style={{ color: '#12c099' }}
                                        >
                                            Country
                                        </TableSortLabel>
                                    </CompactTableCell>
                                    <CompactTableCell>
                                        <TableSortLabel
                                            active={orderBy === 'state'}
                                            direction={orderBy === 'state' ? order : 'asc'}
                                            onClick={() => handleSortRequest('state')}
                                            style={{ color: '#12c099' }}
                                        >
                                            State
                                        </TableSortLabel>
                                    </CompactTableCell>
                                    <CompactTableCell>
                                        <TableSortLabel
                                            active={orderBy === 'city'}
                                            direction={orderBy === 'city' ? order : 'asc'}
                                            onClick={() => handleSortRequest('city')}
                                            style={{ color: '#12c099' }}
                                        >
                                            City
                                        </TableSortLabel>
                                    </CompactTableCell>
                                    <CompactTableCell>
                                        <TableSortLabel
                                            active={orderBy === 'teamLeader'}
                                            direction={orderBy === 'teamLeader' ? order : 'asc'}
                                            onClick={() => handleSortRequest('teamLeader')}
                                            style={{ color: '#12c099' }}
                                        >
                                            Team Leader
                                        </TableSortLabel>
                                    </CompactTableCell>
                                    <CompactTableCell>
                                        <TableSortLabel
                                            active={orderBy === 'coverage'}
                                            direction={orderBy === 'coverage' ? order : 'asc'}
                                            onClick={() => handleSortRequest('coverage')}
                                            style={{ color: '#12c099' }}
                                        >
                                            Coverage %
                                        </TableSortLabel>
                                    </CompactTableCell>
                                    <CompactTableCell>
                                        <TableSortLabel
                                            active={orderBy === 'revenue'}
                                            direction={orderBy === 'revenue' ? order : 'asc'}
                                            onClick={() => handleSortRequest('revenue')}
                                            style={{ color: '#12c099' }}
                                        >
                                            Revenue
                                        </TableSortLabel>
                                    </CompactTableCell>
                                    <CompactTableCell>
                                        <TableSortLabel
                                            active={orderBy === 'margin'}
                                            direction={orderBy === 'margin' ? order : 'asc'}
                                            onClick={() => handleSortRequest('margin')}
                                            style={{ color: '#12c099' }}
                                        >
                                            Margin
                                        </TableSortLabel>
                                    </CompactTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {paginatedMarkets.map((market) => (
                                    <CompactTableRow key={market.id}>
                                        <CompactTableCell onClick={() => handleCountryClick(market.country)}>{market.country}</CompactTableCell>
                                        <CompactTableCell onClick={() => handleStateClick(market.state)}>{market.state}</CompactTableCell>
                                        <CompactTableCell onClick={() => handleCityClick(market.city)}>{market.city}</CompactTableCell>
                                        <CompactTableCell>{market.teamLeader}</CompactTableCell>
                                        <CompactTableCell>{market.coverage}%</CompactTableCell>
                                        <CompactTableCell>${market.revenue.toLocaleString()}</CompactTableCell>
                                        <CompactTableCell>{market.margin}%</CompactTableCell>
                                    </CompactTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </StyledTableContainer>
                </Grid>
            </Grid>
            <PaginationBox>
                <Pagination
                    count={Math.ceil(filteredMarkets.length / rowsPerPage)}
                    page={page}
                    onChange={(event, value) => setPage(value)}
                    sx={{ button: { color: '#12c099' }, ul: { color: '#12c099' } }}
                />
            </PaginationBox>
        </Container>
    );
};

export default Markets;
